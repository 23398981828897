import { addMiddleware } from 'redux-dynamic-middlewares';
import LuckyDipBetslip from './components/lucky_dip_betslip';
import { reducer } from './ducks';
import LuckyDipBetslipMiddleware from './middleware';

const { addReducers } = window.reduxState;

addReducers({ luckyDipBetslip: reducer });
addMiddleware(LuckyDipBetslipMiddleware);

export default LuckyDipBetslip;
