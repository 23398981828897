import { placeBet } from '../api';
import { betslipSubmitComplete, betslipSubmitInit } from './action_creators';
import { selectLuckyDip } from './selectors';

export const submitBetslip = () => (dispatch, getState) => {
  dispatch(betslipSubmitInit());

  const state = getState();
  placeBet({
    ...state.luckyDipBetslip,
    luckyDip: selectLuckyDip(state),
  }).then((response) => {
    dispatch(betslipSubmitComplete(response));
  });
};
