import { createSelector } from 'reselect';
import { isArray, compact } from 'underscore';

const getEventsMarkets = (state) => state['sportsbook/overview'].eventsActiveMarket || {};
const getMarkets = (state) => state.markets || {};
const getPropsEventId = (_, props) => props.eventId;

export default () => createSelector(
  getEventsMarkets,
  getMarkets,
  getPropsEventId,
  (eventsMarkets, markets, eventId) => {
    const eventMarketIds = isArray(eventsMarkets[eventId])
      ? eventsMarkets[eventId]
      : compact([eventsMarkets[eventId]]);

    return markets[eventMarketIds[0]];
  },
);
