import PropTypes from 'prop-types';

const ClockInfo = ({
  isOutright,
  isInplay,
  startTimeComponent,
  clockComponent,
  periodComponent,
}) => (
  isOutright ? null : (
    <div className="inplay-coupon-favtime-column">
      {isInplay ? (
        <>
          {periodComponent}
          {clockComponent}
        </>
      ) : startTimeComponent}
    </div>
  )
);

ClockInfo.propTypes = {
  isOutright: PropTypes.bool,
  isInplay: PropTypes.bool.isRequired,
  startTimeComponent: PropTypes.node.isRequired,
  clockComponent: PropTypes.node.isRequired,
  periodComponent: PropTypes.node.isRequired,
};

ClockInfo.defaultProps = {
  isOutright: false,
};

export default ClockInfo;
