import PropTypes from 'prop-types';
import { eventHref as eventHrefFunc } from 'SportsbookOverview/services';
import EventDetailView from './event_detail_view';

const EventDetailContainer = ({
  description,
  eventHref,
  eventId,
  market,
  meetingId,
  metadata,
  badgeFilter,
  showMore,
  sportEventPathId,
  showClock,
}) => (
  <EventDetailView
    eventHref={eventHref || eventHrefFunc(sportEventPathId, meetingId, eventId)}
    showMore={showMore}
    badges={(metadata?.badges || []).filter(badgeFilter)}
    description={market?.outcomesDescription || description}
    showClock={showClock}
  />
);

EventDetailContainer.propTypes = {
  description: PropTypes.string.isRequired,
  eventHref: PropTypes.string,
  eventId: PropTypes.number.isRequired,
  market: PropTypes.instanceOf(Object),
  meetingId: PropTypes.number,
  metadata: PropTypes.instanceOf(Object).isRequired,
  showMore: PropTypes.bool,
  showClock: PropTypes.bool,
  sportEventPathId: PropTypes.number,
  badgeFilter: PropTypes.func,
};

EventDetailContainer.defaultProps = {
  showMore: true,
  eventHref: undefined,
  sportEventPathId: null,
  meetingId: null,
  market: {},
  showClock: false,
  badgeFilter: () => true,
};

export default EventDetailContainer;
