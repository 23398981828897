import { createSelector } from 'reselect';

const getEventsMarkets = (state) => state['sportsbook/overview'].eventsActiveMarket || {};
const getMarkets = (state) => state.markets || {};
const getPropsEventId = (_, props) => props.eventId;

export default () => createSelector(
  getEventsMarkets,
  getMarkets,
  getPropsEventId,
  (eventsMarkets, markets, eventId) => (eventsMarkets[eventId] || [])
    .map((marketId) => markets[marketId]),
);
