import { createSelector } from 'reselect';
import { intersection } from 'underscore';
import getEvents from './get_events';
import getEventFilters from './get_event_filters';

const getGroupsByCard = (state) => state['sportsbook/overview'].groupsByCard;

export default createSelector(
  getGroupsByCard,
  getEvents,
  getEventFilters,
  (groupsByCard, events, { labels = [] }) => (labels.length ? (
    Object.keys(groupsByCard).reduce((acc, key) => ({
      ...acc,
      [key]: groupsByCard[key].map((group) => ({
        ...group,
        children: group.children.filter((eventId) => (
          intersection(labels, events[eventId].labels).length
        )),
      })),
    }), {})
  ) : groupsByCard),
);
