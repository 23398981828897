import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { isOpen } from '../helpers';

const LuckyDipStatus = ({ luckyDip }) => {
  if (isOpen(luckyDip)) return null;

  return (
    <div className="betslip__status is-warning">
      <span className="bvs-icon is-warning" />
      <span>
        {t('betslip.header.selection_unavailable')}
      </span>
    </div>
  );
};

LuckyDipStatus.propTypes = {
  luckyDip: PropTypes.instanceOf(Object).isRequired,
};

export default LuckyDipStatus;
