export default ({ multiple, stake, betslipType }) => (
  {
    s: (stake.value && stake.value.replace(',', '.')) || '0',
    ews: stake.ews || false,
    sp_selected: false, // TODO
    ew: multiple.hasEW,
    place_terms_deduction: multiple.placeTermsDeduction,
    place_terms_description: multiple.placeTermsDescription,
    click_source: multiple.clickSource,
    price_text_formatted: multiple.textFormattedPrice,
    quick_bet: betslipType === 'mini-betslip',
  }
);
