import { createSelector } from 'reselect';
import getEvents from './get_events';

const getPropsEventId = (_, props) => props.eventId;

export default () => createSelector(
  getPropsEventId,
  getEvents,
  (eventId, events) => events[eventId],
);
