import { useState } from 'react';
import PropTypes from 'prop-types';
import { usePriceFormatId } from 'bv-hooks';
import { Button } from 'bv-components';
import { format as formatPrice } from 'bv-helpers/price';
import { t } from 'bv-i18n';
import { useDispatch } from 'react-redux';
import { addToBetslip } from '../../lucky_dip_betslip/ducks/action_creators';
import SelectionsModal from './selections_modal';

const LuckyDip = ({
  luckyDip: {
    description,
    eventDescription,
    formattedPrice,
  },
  luckyDip,
  showEventDescription,
}) => {
  const dispatch = useDispatch();
  const [showSelectionsModal, setShowSelectionsModal] = useState(false);
  const priceFormatId = usePriceFormatId();

  const handleSubmitClick = () => {
    dispatch(addToBetslip(luckyDip));
  };

  return (
    <div className="bvs-card ld-card">
      <span className="ld-card__logo" />
      <p className="ld-card__title">{description}</p>
      {showEventDescription && <p className="ld-card__event bvs-ellipsis">{eventDescription}</p>}
      <Button onClick={handleSubmitClick} primary className="ld-card__button bvs-ellipsis">
        {t('lucky_dip.component.take_lucky_dip', { value: formatPrice(formattedPrice, priceFormatId) })}
      </Button>
      <div className="ld-card__label bvs-link" onClick={() => setShowSelectionsModal(true)}>
        {t('lucky_dip.component.view_outcomes')}
      </div>
      {showSelectionsModal && (
        <SelectionsModal
          luckyDipId={luckyDip.id}
          onClose={() => setShowSelectionsModal(false)}
        />
      )}
    </div>
  );
};

LuckyDip.propTypes = {
  luckyDip: PropTypes.instanceOf(Object).isRequired,
  showEventDescription: PropTypes.bool.isRequired,
};

export default LuckyDip;
