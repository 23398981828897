export default (rawMultiple) => ({
  description: rawMultiple.description,
  winBetTypeId: rawMultiple.win_bet_type_id,
  eachWayBetTypeId: rawMultiple.each_way_bet_type_id,
  ew: rawMultiple.ew,
  oddsEW: rawMultiple.odds_ew,
  originalOddsEW: rawMultiple.odds_ew,
  decimalPrice: rawMultiple.odds,
  originalDecimalPrice: rawMultiple.odds,
  textFormattedPrice: rawMultiple.odds_formatted,
  originalTextFormattedPrice: rawMultiple.odds_formatted,
  multiplicity: rawMultiple.multiplicity,
  numberOfUnits: rawMultiple.number_of_units,
  bonusFundsEligible: rawMultiple.bonus_funds_eligible,
  taxDeductPrc: rawMultiple.tax_deduct_prc,
  outcomeLegs: rawMultiple.outcome_legs,
  // ... more to come when we actually start using these in a react-view
});
