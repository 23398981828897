import { dataLayer } from 'bv-helpers';
import { getCurrencyCode } from 'bv-helpers/price';
import { accountNumber } from 'bv-helpers/session';
import { makeGetSingle } from './selector';

// TODO: duplicated function in frontend, remove when migrated
export const hasPriceChanged = (single) => {
  if (single.decimalPrice === 0) return false; // SP Price
  return single.originalDecimalPrice !== single.decimalPrice;
};

export const pushRemoveOutcomeEvent = (outcomeId, state) => {
  const single = makeGetSingle()(state, { outcomeId });
  dataLayer.push({
    event: 'evt_remove_betslip',
    account_number: accountNumber(),
    outcome_id: outcomeId,
    outcome_status: (state.outcomes[outcomeId] || {}).os,
    price_change: single ? hasPriceChanged(single) : false,
  });
};

export const pushAddOutcomeEvent = (outcomeId) => {
  dataLayer.push({
    event: 'evt_add_to_betslip',
    account_number: accountNumber(),
    outcome_id: outcomeId,
  });
};

export const pushBetSubmitEvent = ({
  id, multiples, outcomes, singles,
}, betSlipType) => {
  const stakeAmount = Array.from(singles).concat(multiples)
    .reduce(((acc, r) => acc + r.totalStake), 0);
  dataLayer.push({
    event: 'evt_bet',
    account_number: accountNumber(),
    bet_id: id,
    bet_source: betSlipType,
    selection_count: outcomes.allIds.length,
    stake_amount: stakeAmount,
    stake_currency: getCurrencyCode(),
  });
};
