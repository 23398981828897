import classnames from 'classnames';

export default {
  marketOptions: ({ marketTypeDimension, periodDimension, outcomesPerEvent }) => ({
    marketTypeDimension: marketTypeDimension.value,
    periodDimension: periodDimension.value,
    outcomesPerEvent,
  }),
  cardBodyClassNames: ({ betBuilder2 }) => classnames('piu-events', { betBuilder2 }),
};
