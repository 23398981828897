import { createSelector } from 'reselect';
import { sorting as getSortingMethods } from 'SportsbookOverview/services';
import getEvents from './get_events';
import getGroupsByCard from './get_groups_by_card';
import getSorting from './get_sorting';

const getPropsCardId = (_, props) => props.cardId;
const getPropsSorting = (_, props) => props.sorting;

export default () => createSelector(
  getPropsCardId,
  getPropsSorting,
  getGroupsByCard,
  getSorting,
  getSortingMethods,
  getEvents,
  (cardId, sorting, groupsByCard, { method, direction }, sortingMethods, events) => (sorting ? (
    sortingMethods
      .find((s) => s.id === method)
      .sort(groupsByCard, events, direction)
  ) : groupsByCard)[cardId],
);
