import { fetchLuckyDip as apiFetchLuckyDip } from '../../api';
import { addLuckyDips } from './action_creators';

export const fetchLuckyDip = (id) => (dispatch) => {
  apiFetchLuckyDip(id).then((response) => {
    if (response) {
      dispatch(addLuckyDips({ [id]: response }));
    }
  });
};
