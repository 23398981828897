import { createSelector } from 'reselect';
import getEvents from './get_events';

const getPropsEventIds = (_, props) => props.eventIds || [];

export default () => createSelector(
  getEvents,
  getPropsEventIds,
  (events, eventIds) => eventIds
    .map((eventId) => events[eventId] || {})
    .some((event) => event.inPlay),
);
