import betAddPresenter from './bet_add_presenter';

export default ({ outcomeIds, priceFormatId }) => (
  {
    bets: outcomeIds.map((id) => betAddPresenter({
      outcome: { id },
      market: {},
      opts: {},
    }).bet),
    price_format_id: priceFormatId,
  }
);
