/* eslint-disable no-param-reassign */
import { assetPath, v as bvVar } from 'bv';
import { format as formatPrice } from 'bv-helpers/price';
import { getTextReplacements } from './text_replacements';

const replaceTexts = (elements, textReplacements) => {
  elements.forEach((element) => {
    if (element.updateDocumentData && textReplacements[element.data.nm]) {
      element.updateDocumentData(textReplacements[element.data.nm]);
    }
    if (element.elements) {
      replaceTexts(element.elements, textReplacements);
    }
  });
};

export const replaceDynamicValues = ({ animation, selections, formattedPrice }) => {
  const textReplacements = getTextReplacements(selections, formatPrice(formattedPrice));
  replaceTexts(animation.renderer.elements, textReplacements);
};

export const replaceAssetPaths = (animation) => {
  const luckyDipAssets = bvVar('luckyDipAssets');

  animation.assets.forEach((asset) => {
    if (luckyDipAssets.images[asset.p]) {
      asset.p = assetPath(luckyDipAssets.images[asset.p]);
      asset.e = 1;
    }
  });
};
