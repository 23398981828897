import { t } from 'bv-i18n';
import { toCurrency } from 'bv-helpers/number';
import { format as priceFormat } from 'bv-helpers/price';
import { setBetslipTooltip } from 'floating-navigation-helpers';
import { notify } from 'bv-error-boundary';

export const patternForLineInDescriptions = /((?![:a-z])[+-]?\d+[+-.,0-9]*(?![:a-z]))/ig;

export const hasPriceChanged = (single) => {
  if (single.decimalPrice === 0) return false; // SP Price
  return single.originalDecimalPrice !== single.decimalPrice;
};

// Outcomes can be hidden, but bettable (for example: customer built bet-builder outcomes)
// we need to ignore the hidden flag if the .os field === 1. The os field will be populated
// from betslipShow and present on all bets on bet-add
export const isHidden = (outcome) => (
  (outcome.os === 1)
    ? false
    : outcome.h || outcome.hidden || false
);

// Backends don't update .ms and .os consistently yet, so better check both
// of those fields. If any of them isn't === 1, the outcome is unavailable
export const isSuspended = (outcome) => (
  (outcome.os && outcome.os !== 1) || (outcome.ms && outcome.ms !== 1) || false
);

export const isAnySuspended = (outcomes) => outcomes.some(isSuspended);

export const isAnyWithLineDistance = (singles) => singles.some(
  (single) => Number.isInteger(single.outcomeLineDistance),
);

export const isAnySuspendedOrHidden = (outcomes) => (
  outcomes.some(isSuspended) || outcomes.some(isHidden)
);

export const getStakeId = (bet) => {
  if (!bet) return null;
  if (bet.outcomeId) return `s-${bet.outcomeId}`;
  if (bet.winBetTypeId) return `m-${bet.winBetTypeId}`;
  return null;
};

export const getStakeValue = (stake) => (stake.value !== '' ? parseFloat(stake.value.replace(',', '.')) : 0);

export const hasAtLeastOneStake = (outcomes, singles, multiples, stakes) => (
  !!(
    [
      ...singles.filter((s) => {
        // ignore hidden or suspended outcomes even if they have stakes
        const outcome = outcomes.find((o) => o.id === s.outcomeId);
        return outcome && !isHidden(outcome) && !isSuspended(outcome);
      }),
      // only include multiples in stake-checks if none of the outcomes are suspended or hidden
      // if some are, none of the multiples count as "available" and their stakes are to be ignored
      ...(isAnySuspendedOrHidden(outcomes) ? [] : multiples),
    ]
      .map(getStakeId)
      .find((stakeId) => stakes[stakeId] && getStakeValue(stakes[stakeId]) !== 0)
  )
);

export const hasAnyPriceChanges = (singles) => (
  singles.some((single) => hasPriceChanged(single))
);

export const isAllSuspended = (outcomes) => (
  outcomes.every((outcome) => isSuspended(outcome))
);

export const isAllHidden = (outcomes) => outcomes.every(isHidden);

export const isAllHiddenOrSuspended = (outcomes) => (
  outcomes.every((outcome) => isSuspended(outcome) || isHidden(outcome))
);

export const hasEnoughBalance = (totalStake, balances, useBonus = false) => (
  balances && (
    balances[`decimal${(useBonus ? 'Bonus' : 'Main')}Balance`] === null
    || parseFloat(totalStake) <= balances[`decimal${(useBonus ? 'Bonus' : 'Main')}Balance`]
  )
);

export const potentialReturns = (returns) => (
  returns > 0
    ? toCurrency(returns, { decimalPlaces: 2 })
    : t('javascript.na')
);

export const multiplesFilter = (tabsEnabled, currentTab, expanded) => {
  if (!tabsEnabled && expanded) return () => true;
  if (currentTab === 'singles') return () => false;
  if (currentTab === 'acca' || (!tabsEnabled && !expanded)) return (m) => m.multiplicity === 1;
  if (currentTab === 'multiples') return (m) => m.multiplicity > 1;
  // should never happen, only here to make eslint happy
  return () => true;
};

export const singlesFilter = (tabsEnabled, currentTab) => {
  if (!tabsEnabled || currentTab === 'singles') return () => true;
  return () => false;
};

export const handledBetIssues = ['reoffered_stake', 'bet_attendant_rejected'];

export const showMeetingSportIds = [
  200, // horse racing
  433200, // dogs
];

export const mergeEnhancedOutcomesWithSingles = (enhancedOutcomesById, singles) => (
  {
    ...enhancedOutcomesById,
    ...(singles.reduce((acc, single) => {
      if (single.fractionalEnhancedOdds) {
        acc[single.outcomeId] = {
          active: true,
          fractionalEnhancedOdds: single.fractionalEnhancedOdds,
          minStake: single.minStake,
          maxStake: single.maxStake,
        };
      }
      return acc;
    }, {})),
  }
);

export const receiptGrossTotalReturns = (bets) => {
  const hasAnyWithoutPrice = bets.some((bet) => bet.formattedPrice === '');
  return (
    hasAnyWithoutPrice
      ? 0
      : bets.reduce((acc, bet) => acc + bet.potentialReturns.grossTotal, 0)
  );
};

export const hasLineFollowed = (single) => single.lineFollowed || false;
export const hasAnyLineFollowed = (singles) => (
  singles.some((single) => hasLineFollowed(single))
);

export const hasLineChanged = (single) => (
  single.originalOutcomeLineDistance !== single.outcomeLineDistance
);
export const hasAnyLineChanges = (singles) => singles.some(hasLineChanged);

export const splitOutcomeDescriptionWithLine = (description) => {
  const parts = description.match(/^(.+?)([-()+,.0-9]+)$/);
  if (description.match(/(\d{1,2}:\d\d-\d{1,2}:\d\d)/)) return [parts[0], ''];
  if (parts?.length === 3) return parts.slice(1);
  return [description, ''];
};

export const outcomePricePairs = (singles) => (
  singles.map((single) => ({
    outcomeId: single.outcomeId,
    price: single.decimalPrice,
  }))
);

const generateBetDeltas = (bet, prices) => {
  const { winBetTypeId } = bet;
  const { eachWayBetTypeId } = bet;

  const delta = {};

  // forecast and tricast bets are categorised as "singles" on
  // /rest/returnsMultiplicationFactor endpoint, so we don't always
  // have prices for every multiple that betslipShow was returning
  if (!prices.pricesByBetTypeId[winBetTypeId]) {
    return {
      decimalPrice: 0,
      textFormattedPrice: '',
      oddsEW: 0,
    };
  }

  if (winBetTypeId) {
    delta.decimalPrice = parseFloat(prices.pricesByBetTypeId[winBetTypeId]);
    if (prices.formattedPricesByBetTypeId) {
      delta.textFormattedPrice = prices.formattedPricesByBetTypeId[winBetTypeId];
    }
  }

  if (eachWayBetTypeId) {
    delta.oddsEW = prices.pricesByBetTypeId[eachWayBetTypeId];
  }

  return delta;
};

export const generatePriceDeltas = (updatedOutcome, singles, multiples, prices) => {
  const singleDeltas = singles.reduce((acc, single) => {
    let singleDelta = generateBetDeltas(single, prices.singlesByOutcomeId[single.outcomeId]);
    if (single.outcomeId === updatedOutcome.id) {
      singleDelta = {
        ...singleDelta,
        outcomeId: single.outcomeId,
        priceId: updatedOutcome.prid,
        textFormattedPrice: updatedOutcome.pr,
        decimalPrice: parseFloat(updatedOutcome.prd),
        mblDistance: updatedOutcome.mblDistance,
      };
    }
    acc[single.outcomeId] = singleDelta;
    return acc;
  }, {});

  const multipleDeltas = multiples.reduce((acc, multiple) => {
    acc[multiple.winBetTypeId] = generateBetDeltas(multiple, prices.multiples);
    return acc;
  }, {});

  return [singleDeltas, multipleDeltas];
};

export const validatePriceChanges = (singles, response) => {
  if (outcomePricePairs(singles).some((outcome) => (
    outcome.price !== response.singlesByOutcomeId[outcome.outcomeId].pricesByBetTypeId[1]
  ))) {
    notify(new Error('validatePriceChanges failed'), { singles, response });
    throw new Error('validatePriceChanges failed');
  }
  return response;
};

export const showMultiplesTooltip = (multiples) => {
  // when redux feature flipper is off, this is always missing
  if (!multiples) return;

  const multipleForOdds = multiples
    .filter((multiple) => multiple.multiplicity === 1)
    .sort((a, b) => b.numberOfUnits - a.numberOfUnits)[0];

  // Set the tooltip to empty string to clear out previously shown
  // and still animating tooltip when you are clearing the betslip
  // or removing lot of outcomes in rapid succession
  if (!multipleForOdds || multipleForOdds.decimalPrice === 0) {
    setBetslipTooltip('');
    return;
  }

  const translationKey = `javascript.betslip_acca_tooltips.${Math.min(6, multipleForOdds.numberOfUnits)}`;

  if (multipleForOdds.odds_formatted !== '') {
    setBetslipTooltip(
      `${t(translationKey)} ${priceFormat(multipleForOdds.textFormattedPrice)}`,
    );
  }
};

export const extractLineValueFromDescription = (description, extra1 = null) => {
  const candidates = description.match(patternForLineInDescriptions);

  if (!candidates?.length) return extra1;

  const line = candidates.find((c) => c.includes(extra1));
  if (line) return line;
  return extra1;
};

export const updateLineDescription = (oldDescription, newDescription, extra1 = null) => {
  const newLine = extractLineValueFromDescription(newDescription, extra1);
  const matchCount = oldDescription.match(patternForLineInDescriptions).length;
  const oldLineMatches = oldDescription.match(patternForLineInDescriptions);
  let oldLine;

  // if only 1 match, pick that one
  if (matchCount === 1) [oldLine] = oldLineMatches;
  if (matchCount > 1) {
    // try finding a match starting with + or -, or with a . or , in it
    oldLine = oldLineMatches.find((p) => p.match(/^[+-]|[.,]$/));
    // if not found pick the value with the closest to the new line
    if (!oldLine) {
      [oldLine] = oldLineMatches.sort(
        (a, b) => {
          const distA = Math.abs(parseFloat(a) - parseFloat(newLine));
          const distB = Math.abs(parseFloat(b) - parseFloat(newLine));
          return distA - distB;
        },
      );
    }
  }

  return newLine && oldLine
    ? oldDescription.replace(oldLine, newLine)
    : newDescription;
};

export const delay = (timeout) => new Promise((resolve) => {
  setTimeout(resolve, timeout);
});
