import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hasSportsbook } from 'global-config';
import { addToBetslip } from '../lucky_dip_betslip/ducks/action_creators';
import { isOpen } from '../lucky_dip_betslip/helpers';
import { makeSelectLuckyDip } from '../lucky_dip_component/ducks/lucky_dips/selectors';
import { fetchLuckyDip } from '../lucky_dip_component/ducks/lucky_dips/thunks';

const AddToBetslip = ({ betSource, buttonComponent: Button, luckyDipId }) => {
  const selectLuckyDip = useMemo(makeSelectLuckyDip, []);
  const luckyDip = useSelector((state) => selectLuckyDip(state, luckyDipId));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLuckyDip(luckyDipId));
  }, [luckyDipId]);

  const handleClick = () => {
    if (hasSportsbook) {
      window.RightSidebar.hide();
    }
    dispatch(addToBetslip(luckyDip));
  };

  if (!isOpen(luckyDip) || !Button) return null;

  return <Button betSource={betSource} onClick={handleClick} />;
};

AddToBetslip.propTypes = {
  betSource: PropTypes.string,
  buttonComponent: PropTypes.bool,
  luckyDipId: PropTypes.string.isRequired,
};

AddToBetslip.defaultProps = {
  betSource: null,
  buttonComponent: null,
};

export default AddToBetslip;
