import { createSelector } from 'reselect';
import getGroupsByCard from './get_groups_by_card';

const propsCardId = (_, props) => props.cardId;

export default () => createSelector(
  getGroupsByCard,
  propsCardId,
  (groups, cardId) => (groups[cardId] || []).reduce(
    (acc, group) => [...acc, ...group.children],
    [],
  ),
);
