import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'underscore';
import { useSelector } from 'react-redux';
import { Redux as EssentialScoresRedux } from 'sportsbook-essential-scores';
import PeriodView from './period_view';

const PeriodRedux = ({ eventId }) => {
  // Stablish redux selector to extract the event info for the given event id
  const getEventScoreboard = useMemo(EssentialScoresRedux.selectors.makeGetEventScoreboard, []);
  const scoreboard = useSelector((state) => getEventScoreboard(state, { eventId }), isEqual);
  // --------------------------------------------

  return (
    <PeriodView scoreboard={scoreboard} />
  );
};

PeriodRedux.propTypes = {
  eventId: PropTypes.number.isRequired,
};

export default PeriodRedux;
