import overview from './overview';
import betBoost from './bet_boost';
import enhancedAccas from './enhanced_accas';
import betBuilder from './bet_builder';
import piuEvents from './piu_events';

export default {
  overview,
  bet_boost: betBoost,
  enhanced_accas: enhancedAccas,
  bet_builder: betBuilder,
  piu_events: piuEvents,
};
