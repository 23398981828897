import { fetchLuckyDipComponent } from '../../api';
import { addLuckyDips } from '../lucky_dips/action_creators';
import { fetchComponentInit, fetchComponentSuccess } from './action_creators';

export const fetchComponent = (params) => (dispatch) => {
  const { componentId } = params;
  dispatch(fetchComponentInit(componentId));

  fetchLuckyDipComponent(params).then((response) => {
    dispatch(fetchComponentSuccess(componentId, response.result));
    dispatch(addLuckyDips(response.entities.luckyDips));
  });
};
