import { isEqual, omit, noop } from 'underscore';

import fetchProgressivePrices from 'EventLevel/api/fetch_progressive_prices';
import fetchProgressiveOutcomes from 'EventLevel/api/fetch_progressive_outcomes';
import { addOutcomes } from 'sportsbook-outcomes-duck';
import { makeGetOutcome } from 'sportsbook-outcomes-selector';
import { addToBetslip } from 'sportsbook-betslip-thunks';
import { buildPriceKey } from './helpers';
import insertFakePriceId from './services/insert_fake_price_id';

const initialState = {
  selectedGroup: null,
  components: {},
  groups: {},
  componentsGroups: {},
  prices: {},
  open: {},
  isLoading: {},
  selectedTabs: {},
  selectedOutcomes: {},
  search: {},
  request: {},
  isSubmitting: false,
  isSubmitFailed: false,
};

const INIT = 'eventLevel/betBuilder/INIT';
const CHANGE_GROUP = 'eventLevel/betBuilder/CHANGE_GROUP';
const LOAD_PRICES_INIT = 'eventLevel/betBuilder/LOAD_PRICES_INIT';
const LOAD_PRICES_COMPLETE = 'eventLevel/betBuilder/LOAD_PRICES_COMPLETE';
const TOGGLE_COMPONENT = 'eventLevel/betBuilder/TOGGLE_COMPONENT';
export const SELECT_OUTCOME = 'eventLevel/betBuilder/SELECT_OUTCOME';
const DESELECT_OUTCOME = 'eventLevel/betBuilder/DESELECT_OUTCOME';
const DESELECT_LEG = 'eventLevel/betBuilder/DESELECT_LEG';
const CHANGE_TAB = 'eventLevel/betBuilder/CHANGE_TAB';
const SEARCH_OUTCOME = 'eventLevel/betBuilder/SEARCH_OUTCOME';
const SUBMIT_LEGS_INIT = 'eventLevel/betBuilder/SUBMIT_LEGS_INIT';
const SUBMIT_LEGS_COMPLETE = 'eventLevel/betBuilder/SUBMIT_LEGS_COMPLETE';
const RESET_SELECTED_OUTCOMES = 'eventLevel/betBuilder/RESET_SELECTED_OUTCOMES';
const RESTORE_SELECTED_OUTCOMES = 'eventLevel/betBuilder/RESTORE_SELECTED_OUTCOMES';
const SET_IS_SUBMIT_FAILED = 'eventLevel/betBuilder/SET_IS_SUBMIT_FAILED';
const RESET_STATE = 'eventLevel/betBuilder/RESET_STATE';

export const betBuilderInit = (payload) => ({
  type: INIT,
  payload,
});

export const changeGroup = (payload) => ({
  type: CHANGE_GROUP,
  payload,
});

export const loadPricesInit = (payload) => ({
  type: LOAD_PRICES_INIT,
  payload,
});

export const loadPricesComplete = (payload) => ({
  type: LOAD_PRICES_COMPLETE,
  payload,
});

export const toggleComponent = (payload) => ({
  type: TOGGLE_COMPONENT,
  payload,
});

export const selectOutcome = (payload) => ({
  type: SELECT_OUTCOME,
  payload,
});

export const deselectOutcome = (payload) => ({
  type: DESELECT_OUTCOME,
  payload,
});

export const deselectLeg = (payload) => ({
  type: DESELECT_LEG,
  payload,
});

export const changeTab = (payload) => ({
  type: CHANGE_TAB,
  payload,
});

export const searchOutcome = (payload) => ({
  type: SEARCH_OUTCOME,
  payload,
});

export const submitLegsInit = () => ({
  type: SUBMIT_LEGS_INIT,
});

export const submitLegsComplete = () => ({
  type: SUBMIT_LEGS_COMPLETE,
});

export const resetSelectedOutcomes = () => ({
  type: RESET_SELECTED_OUTCOMES,
});

export const restoreSelectedOutcomes = (payload) => ({
  type: RESTORE_SELECTED_OUTCOMES,
  payload,
});

export const setIsSubmitFailed = (payload) => ({
  type: SET_IS_SUBMIT_FAILED,
  payload,
});

export const resetState = () => ({
  type: RESET_STATE,
});

const uniqArray = ({ acc, newValue }) => Array.from(new Set([...(acc || []), newValue]));

export const fetchPrices = ({ eventId, components, legs }) => async (dispatch) => {
  dispatch(loadPricesInit(components));

  const data = await fetchProgressivePrices(eventId, legs, components);

  return dispatch(loadPricesComplete({
    data,
    components,
  }));
};

export const submitLegs = ({
  eventId,
  legs,
  lastPrice,
  afterSubmit = noop,
  clickSource,
}) => async (dispatch, getState) => {
  dispatch(submitLegsInit());

  const {
    outcomeId,
    outcomeDescription,
    fractionalPrice,
    decimalPrice,
    priceId,
    error,
  } = await fetchProgressiveOutcomes(eventId, legs);

  const addOutcome = () => {
    dispatch(addOutcomes([{
      id: outcomeId,
      desc: outcomeDescription,
      pr: fractionalPrice,
      prd: decimalPrice,
      prid: priceId,
    }]));
  };

  const handleOutcome = () => {
    const state = getState();
    const outcomeInState = makeGetOutcome()(state, { id: outcomeId });

    if (!outcomeInState) {
      addOutcome();
    }

    dispatch(addToBetslip(outcomeId, { clickSource }, (response) => {
      if (lastPrice === fractionalPrice) {
        return response;
      }
      return {
        ...response,
        singles: insertFakePriceId(response.singles, outcomeId),
      };
    }));
    dispatch(resetSelectedOutcomes());
    dispatch(setIsSubmitFailed(false));
  };

  if (error) {
    dispatch(setIsSubmitFailed(true));
    setTimeout(() => {
      dispatch(setIsSubmitFailed(false));
    }, 5000);
  } else {
    handleOutcome();
  }

  dispatch(submitLegsComplete());
  return afterSubmit();
};

export const reduceGridYOptions = (price, yOptions) => ({
  ...price,
  grid: {
    ...price.grid,
    y: {
      ...price.grid.y,
      options: yOptions,
    },
  },
});

const reduceChangeGroup = (state, value) => ({
  ...state,
  selectedGroup: value,
});

const reduceChangeTab = (state, { componentKey, tabKey }) => ({
  ...state,
  selectedTabs: {
    ...state.selectedTabs,
    [componentKey]: tabKey,
  },
});

const reduceComponents = (initialAcc, group) => (
  group.components.reduce((acc, component) => ({
    ...acc,
    components: {
      ...acc.components,
      byId: {
        ...acc.components.byId || {},
        [component.key]: component,
      },
      allIds: uniqArray({
        acc: acc.components.allIds,
        newValue: component.key,
      }),
    },
    componentsGroups: {
      ...acc.componentsGroups,
      [group.id]: [
        ...(acc.componentsGroups[group.id] || []),
        component.key,
      ],
    },
    open: {
      ...acc.open,
      [group.id]: [
        ...(acc.open[group.id] || []),
        ...(component.open_by_default ? [component.key] : []),
      ],
    },
  }), initialAcc)
);

const normalizeCoupon = (coupon) => (
  coupon.component_groups.reduce((acc, group) => ({
    ...acc,
    ...reduceComponents(acc, group),
    groups: {
      ...acc.groups,
      byId: {
        ...acc.groups.byId || {},
        [group.id]: {
          id: group.id,
          description: group.description,
        },
      },
      allIds: uniqArray({
        acc: acc.groups.allIds,
        newValue: group.id,
      }),
    },
  }), {
    components: {},
    groups: {},
    componentsGroups: {},
    open: {},
  })
);

const reduceDropdownOptions = (options) => (
  options.reduce((acc, option) => ({
    ...acc,
    byId: {
      ...acc.byId || {},
      [option.key]: option,
    },
    allIds: uniqArray({
      acc: acc.allIds,
      newValue: option.key,
    }),
  }), {
    byId: {},
    allIds: [],
  })
);

const reducePrices = (componentKey, prices) => (
  prices.reduce((acc, price) => ({
    ...acc,
    [buildPriceKey({ componentKey, xKey: price.x, yKey: price.y })]: price,
  }), {})
);

const reduceSelectedTabs = (componentKey, options) => (
  options.reduce((acc, option) => ({
    ...acc,
    ...(option.selected ? { [componentKey]: option.key } : {}),
  }), {})
);

const normalizePrices = (prices) => {
  if (!prices.components) return {};

  return prices.components.reduce((acc, component) => {
    const { selections, selectors } = component.container || {};
    const dropdown = selectors.dropdown || {};
    const options = dropdown.options || [];

    return {
      ...acc,
      prices: {
        ...acc.prices,
        [component.type]: {
          selections,
          dropdown: reduceDropdownOptions(options),
          grid: {
            ...selectors.grid,
            prices: reducePrices(component.type, selectors.grid.prices),
          },
        },
      },
      selectedTabs: {
        ...acc.selectedTabs,
        ...reduceSelectedTabs(component.type, options),
      },
    };
  },
  {
    prices: {},
    selectedTabs: {},
  });
};

const normalizeLegs = (state, request) => {
  if (!request) return {};

  return (
    request.legs.reduce((acc, leg) => ({
      ...acc,
      byId: {
        ...acc.byId || {},
        [leg.hash]: leg,
      },
      allIds: uniqArray({
        acc: acc.allIds,
        newValue: leg.hash,
      }),
    }), {})
  );
};

const reduceInit = (state, coupon) => {
  const data = normalizeCoupon(coupon);
  const allIds = data.groups.allIds || [];
  const selectedGroup = Number(allIds[0]);

  return {
    ...state,
    ...data,
    selectedGroup,
  };
};

const reduceComponentLoading = (state, components, value) => (
  components.reduce((acc, { type }) => ({ ...acc, [type]: value }), state.isLoading)
);

const reduceLoadPricesInit = (state, components) => ({
  ...state,
  isLoading: reduceComponentLoading(state, components, true),
});

const reduceLoadPricesComplete = (state, { data, components }) => {
  const { prices, selectedTabs } = normalizePrices(data);

  return {
    ...state,
    prices: {
      ...state.prices,
      ...prices,
    },
    selectedTabs: {
      ...state.selectedTabs,
      ...selectedTabs,
    },
    request: {
      ...(data.request || {}),
      legs: normalizeLegs(state, data.request),
    },
    isLoading: reduceComponentLoading(state, components, false),
  };
};

const reduceToggleComponent = (state, key) => {
  const open = state.open[state.selectedGroup];
  const newState = open.includes(key) ? open.filter((val) => val !== key) : [...open, key];

  return {
    ...state,
    open: {
      ...state.open,
      [state.selectedGroup]: newState,
    },
  };
};

const reduceSelectOutcome = (state, { componentKey, outcomeKey }) => {
  const { prices, selectedTabs } = state;

  const price = prices[componentKey];
  const tabId = selectedTabs[componentKey];
  const tab = price.dropdown.byId[tabId] || {};
  const outcome = price.grid.prices[outcomeKey];

  return {
    ...state,
    selectedOutcomes: {
      ...state.selectedOutcomes,
      byId: {
        ...state.selectedOutcomes.byId || {},
        [outcomeKey]: {
          ...price.selections,
          ...outcome.selections,
          ...tab.selections || {},
        },
      },
      allIds: uniqArray({
        acc: state.selectedOutcomes.allIds,
        newValue: outcomeKey,
      }),
    },
  };
};

const reduceDeselectOutcome = (state, outcomeId) => {
  const { allIds, byId } = state.selectedOutcomes;
  const newById = omit(byId, outcomeId);
  const newAllIds = allIds.filter((id) => id !== outcomeId);

  return {
    ...state,
    selectedOutcomes: {
      ...state.selectedOutcomes,
      byId: newById,
      allIds: newAllIds,
    },
  };
};

const reduceDeselectLeg = (state, legHash) => {
  const { allIds, byId } = state.selectedOutcomes;
  const { byId: byHash } = state.request.legs;
  const { selections } = byHash[legHash];
  const outcomeId = allIds.find((id) => isEqual(byId[id], selections));

  return reduceDeselectOutcome(state, outcomeId);
};

const reduceSearchOutcome = (state, { componentKey, searchTerm }) => ({
  ...state,
  search: {
    ...state.search,
    [componentKey]: searchTerm,
  },
});

const reduceSetIsSubmitFailed = (state, isSubmitFailed) => ({
  ...state,
  isSubmitFailed,
});

const reduceSubmitLegsStatus = (state, isSubmitting) => ({
  ...state,
  isSubmitting,
});

const reduceResetSelectedOutcomes = (state) => ({
  ...state,
  selectedOutcomes: {
    byId: {},
    allIds: [],
  },
});

const reduceRestoreSelectedOutcomes = (state, selectedOutcomes) => ({
  ...state,
  selectedOutcomes,
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case INIT:
      return reduceInit(initialState, action.payload);
    case CHANGE_GROUP:
      return reduceChangeGroup(state, action.payload);
    case LOAD_PRICES_INIT:
      return reduceLoadPricesInit(state, action.payload);
    case LOAD_PRICES_COMPLETE:
      return reduceLoadPricesComplete(state, action.payload);
    case TOGGLE_COMPONENT:
      return reduceToggleComponent(state, action.payload);
    case SELECT_OUTCOME:
      return reduceSelectOutcome(state, action.payload);
    case DESELECT_OUTCOME:
      return reduceDeselectOutcome(state, action.payload);
    case DESELECT_LEG:
      return reduceDeselectLeg(state, action.payload);
    case CHANGE_TAB:
      return reduceChangeTab(state, action.payload);
    case SEARCH_OUTCOME:
      return reduceSearchOutcome(state, action.payload);
    case SUBMIT_LEGS_INIT:
      return reduceSubmitLegsStatus(state, true);
    case SUBMIT_LEGS_COMPLETE:
      return reduceSubmitLegsStatus(state, false);
    case RESET_SELECTED_OUTCOMES:
      return reduceResetSelectedOutcomes(state);
    case RESTORE_SELECTED_OUTCOMES:
      return reduceRestoreSelectedOutcomes(state, action.payload);
    case SET_IS_SUBMIT_FAILED:
      return reduceSetIsSubmitFailed(state, action.payload);
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};
