export default {
  marketOptions: ({
    marketTypeDimension, periodDimension, couponOutcomeKeys, ignoreOutcomeKeys,
  }) => ({
    marketTypeDimension: marketTypeDimension.value,
    periodDimension: periodDimension.value,
    couponOutcomeKeys,
    ignoreOutcomeKeys,
  }),
  cardBodyClassNames: ({ couponType }) => `inplay-${couponType.value.replace(/_/g, '-')}-coupon`,
};
