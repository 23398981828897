import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'underscore';
import { useSelector } from 'react-redux';
import { makeGetEvent } from 'sportsbook-events-selector';
import { makeGetMarket } from 'sportsbook-markets-selector';
import EventDetailContainer from './event_detail_container';

const EventDetailRedux = ({
  eventId,
  marketId,
  showMarketDescription,
  badgeFilter,
}) => {
  // Stablish redux selector to extract the event for the given event id
  const getEvent = useMemo(makeGetEvent, []);
  const event = useSelector((state) => getEvent(state, { eventId }), isEqual);

  // Stablish redux selector to extract the market for the given event id
  const getMarket = useMemo(makeGetMarket, []);
  const market = useSelector((state) => getMarket(state, { marketId }), isEqual);

  return (
    <EventDetailContainer
      eventId={eventId}
      event={event || {}}
      market={market}
      showMarketDescription={showMarketDescription}
      badgeFilter={badgeFilter}
    />
  );
};

EventDetailRedux.propTypes = {
  eventId: PropTypes.number.isRequired,
  marketId: PropTypes.number,
  showMarketDescription: PropTypes.bool,
  badgeFilter: PropTypes.func,
};

EventDetailRedux.defaultProps = {
  marketId: null,
  showMarketDescription: false,
  badgeFilter: () => true,
};

export default EventDetailRedux;
