import { isEqual } from 'underscore';
import { useSelector } from 'react-redux';
import { GeneralErrorView } from 'sportsbook-betslip-components';
import { selectSubmitResponse } from '../ducks/selectors';

const GeneralError = () => {
  const submitResponse = useSelector(selectSubmitResponse, isEqual);

  if (submitResponse?.success !== false) return null;

  return <GeneralErrorView message={submitResponse.errorMessage} />;
};

export default GeneralError;
