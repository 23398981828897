/* eslint import/prefer-default-export: off */

import { createSelector } from 'reselect';
import { makeGetEvent } from 'Sportsbook/events/selector';

const getOutcomes = (state) => state.outcomes;
const getOutcomeId = (_, props) => props.outcomeId || props.outcome_id || props.id;

const makeGetOutcome = () => createSelector(
  getOutcomeId,
  getOutcomes,
  (outcomeId, outcomes) => outcomes[outcomeId],
);

const makeGetRunner = () => createSelector(
  makeGetEvent(),
  getOutcomeId,
  (event, outcomeId) => event
    && event.timeForm
    && event.timeForm.runners
    && event.timeForm.runners[outcomeId],
);

const makeGetSmartStat = () => createSelector(
  makeGetRunner(),
  (runner) => (runner
    ? runner.smartStat
    : null),
);

const getOutcomesIds = (_, props) => props.outcomes || props.outcomesIds;

const makeGetOutcomesByIds = () => createSelector(
  getOutcomesIds,
  getOutcomes,
  (outcomesIds, outcomes) => outcomesIds.map((outcomeId) => outcomes[outcomeId]),
);

const getCurrentMinute = () => (new Date()).getTime() / 1000 / 60;
const getEnhancedOutcomeOffers = (state) => (
  state.enhanedOutcomeOffers ? state.enhanedOutcomeOffers.offers : []
);
const makeGetEnhnacedOutcomeByOutcomeId = () => createSelector(
  getOutcomes,
  getEnhancedOutcomeOffers,
  getOutcomeId,
  getCurrentMinute,
  (outcomes, offers, outcomeId, nowInMinutes) => {
    // find an offer has the enhanced outcome and it's active right now
    const enhancedOffer = offers.find((offer) => (
      offer.enhancedOutcomes[outcomeId] && offer.enhancedOutcomes[outcomeId].active
    ));

    if (enhancedOffer) {
      // skip already expired offers, non-time-limited offers come with 0
      if (enhancedOffer.endDateTime !== 0 && enhancedOffer.endDateTime < nowInMinutes * 60) {
        return null;
      }
      return enhancedOffer.enhancedOutcomes[outcomeId];
    }
    return null;
  },
);

export {
  getOutcomes,
  makeGetOutcome,
  makeGetRunner,
  makeGetSmartStat,
  makeGetOutcomesByIds,
  makeGetEnhnacedOutcomeByOutcomeId,
};
