import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { StakeInputView } from 'sportsbook-betslip-components';
import { setStake as setStakeAction } from '../ducks/action_creators';
import { selectStake } from '../ducks/selectors';

const StakeInput = ({ keyboardRef }) => {
  const stake = useSelector(selectStake);
  const dispatch = useDispatch();
  const setStake = (value) => dispatch(setStakeAction(value));

  return (
    <StakeInputView
      stake={{ value: stake }}
      setStake={setStake}
      keyboardRef={keyboardRef}
    />
  );
};

StakeInput.propTypes = {
  keyboardRef: PropTypes.isRequired,
};

export default StakeInput;
