import { addWithRefCount, removeWithRefCount } from 'bv-helpers/redux';
import { ADD_LUCKY_DIPS, REMOVE_LUCKY_DIPS, UPDATE_LUCKY_DIP } from './action_types';

export const initialState = {};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_LUCKY_DIPS:
      return addWithRefCount(state, action.payload.luckyDips);
    case REMOVE_LUCKY_DIPS:
      return removeWithRefCount(state, action.payload.ids);
    case UPDATE_LUCKY_DIP:
      return {
        ...state,
        [action.payload.luckyDip.id]: action.payload.luckyDip,
      };
    default:
      return state;
  }
};
