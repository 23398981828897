import { partial } from 'underscore';

const MarketStatus = {
  OPEN: 1,
  SUSPENDED: 2,
  CLOSED: 8,
};

const checkStatus = (status, market) => market?.st === status;

export const isMarketClosed = partial(checkStatus, MarketStatus.CLOSED);
export const isMarketOpen = partial(checkStatus, MarketStatus.OPEN);
export const isMarketSuspended = partial(checkStatus, MarketStatus.SUSPENDED);
