import { FETCH_COMPONENT_INIT, FETCH_COMPONENT_SUCCESS, REMOVE_COMPONENT } from './action_types';

export const fetchComponentInit = (id) => ({
  type: FETCH_COMPONENT_INIT,
  payload: { id },
});

export const fetchComponentSuccess = (id, component) => ({
  type: FETCH_COMPONENT_SUCCESS,
  payload: { id, component },
});

export const removeComponent = (id) => ({
  type: REMOVE_COMPONENT,
  payload: { id },
});
