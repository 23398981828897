import { createSelector } from 'reselect';

export const selectLuckyDips = (state) => state.luckyDips;

const getLuckyDipId = (_, luckyDipId) => luckyDipId;

export const makeSelectLuckyDip = () => createSelector(
  [selectLuckyDips, getLuckyDipId],
  (luckyDips, luckyDipId) => luckyDips[luckyDipId],
);
