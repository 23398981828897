import selectors from 'SportsbookOverview/redux/selectors';

export default {
  id: 'default',
  default: true,
  applicable: (state) => Object.values(selectors.getGroupsByCard(state))
    .some((groups) => groups.length !== 1),
  title: 'javascript.overview_sorting.competitions',
  sort: (groupsByCard, _, direction) => {
    // Business has decided that Descending on this context
    // means the order that we've specified in the CMS
    if (direction === 'DESC') return groupsByCard;
    // ------------------------------------------------

    return Object.keys(groupsByCard)
      .reduce((acc, cardId) => {
        const group = groupsByCard[cardId];

        return {
          ...acc,
          [cardId]: [...group].reverse(),
        };
      }, {});
  },
};
