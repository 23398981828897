export default (oldSingles, newSingles) => (
  newSingles.map((newSingle) => {
    const oldSingle = oldSingles.find((s) => s.outcomeId === newSingle.outcomeId);
    if (oldSingle) {
      return {
        ...newSingle,
        originalPriceId: oldSingle.originalPriceId,
        originalDecimalPrice: oldSingle.originalDecimalPrice,
        originalTextFormattedPrice: oldSingle.originalTextFormattedPrice,
        originalOddsEW: oldSingle.originalOddsEW,
      };
    }
    return newSingle;
  })
);
