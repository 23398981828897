import { t } from 'bv-i18n';
import { shuffle } from 'underscore';
import { formatText } from './format_text';
import { fillArrayToLength } from './utils';

const NUMBER_OF_SECTIONS = 24;
const LAST_SPIN_END_SECTIONS = 7;
const LAST_SPIN_INITIAL_SECTIONS = NUMBER_OF_SECTIONS - LAST_SPIN_END_SECTIONS;

const AnimationLabels = {
  SECTION_WINNER: 'section_winner',
  ODDS: 'odds',
  ODDS_ALT: '20/1',
  SECTION: 'section',
  SELECTION: 'selection',
  YOUR_LUCKY_DIP_IS: 'message',
};

const isWinner = (selection) => selection.isWinner;

export const getWinnerDescription = (selections) => selections.find(isWinner).outcomeDescription;

/**
 * Duplicate the selections until we reached the target number of sections
 * To ensure we have a perfect wheel the last section needs to be the last outcome of the list
 */
export const generateWheelSections = (selections) => {
  const shuffledSelections = shuffle(selections);
  const winnerIndex = shuffledSelections.findIndex(isWinner);
  const sortedSelections = [
    ...shuffledSelections.slice(winnerIndex, shuffledSelections.length),
    ...shuffledSelections.slice(0, winnerIndex),
  ];

  return [
    ...fillArrayToLength(shuffledSelections, NUMBER_OF_SECTIONS),
    ...fillArrayToLength(sortedSelections, LAST_SPIN_INITIAL_SECTIONS),
    ...fillArrayToLength([...sortedSelections].reverse(), LAST_SPIN_END_SECTIONS).reverse(),
  ];
};

export const getWheelSectionLabels = (selections) => {
  const wheelSections = generateWheelSections(selections);

  return [...Array(NUMBER_OF_SECTIONS * 2).keys()].reduce((acc, index) => {
    const text = formatText(wheelSections[index].outcomeDescription);
    acc[`${AnimationLabels.SECTION}${index + 1}`] = text;
    acc[`${AnimationLabels.SELECTION}${index + 1}`] = text;
    return acc;
  }, {});
};

export const getTextReplacements = (selections, odds) => ({
  [AnimationLabels.SECTION_WINNER]: formatText(getWinnerDescription(selections)),
  [AnimationLabels.ODDS]: { t: odds },
  [AnimationLabels.ODDS_ALT]: { t: odds },
  [AnimationLabels.YOUR_LUCKY_DIP_IS]: { t: t('lucky_dip.animation.your_lucky_dip_is') },
  ...getWheelSectionLabels(selections),
});
