import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'underscore';
import { useSelector } from 'react-redux';
import { makeGetEvent } from 'sportsbook-events-selector';
import { Redux as EssentialScoresRedux } from 'sportsbook-essential-scores';

const withEventDetailRedux = (EventDetailContainer) => ({ eventId, ...rest }) => {
  // Stablish redux selector to extract the event for the given event id
  const getEvent = useMemo(makeGetEvent, []);
  const event = useSelector((state) => getEvent(state, { eventId }), isEqual);

  // Stablish redux selector to extract the scoreboard info for the given event id
  const { selectors: { makeGetEventNoClockScoreboard } } = EssentialScoresRedux;
  const getEventNoClockScoreboard = useMemo(makeGetEventNoClockScoreboard, []);
  const scoreboard = useSelector((state) => getEventNoClockScoreboard(state, { eventId }), isEqual);
  // --------------------------------------------

  return (
    <EventDetailContainer
      eventId={eventId}
      {...event}
      scoreboard={scoreboard.disabledScoreboard ? {} : scoreboard}
      {...rest}
    />
  );
};

withEventDetailRedux.propTypes = {
  eventId: PropTypes.number.isRequired,
};

export default withEventDetailRedux;
/* eslint react/prop-types: 'off' */
