import PropTypes from 'prop-types';

const Description = ({
  description, eventDescription, periodDescription, promotionName,
}) => (
  <div className="betslip-single__description">
    <div className="betslip-single__outcome">{description}</div>
    <div>{promotionName}</div>
    <div>{periodDescription}</div>
    <div>{eventDescription}</div>
  </div>
);

Description.propTypes = {
  description: PropTypes.string.isRequired,
  eventDescription: PropTypes.string.isRequired,
  periodDescription: PropTypes.string.isRequired,
  promotionName: PropTypes.string.isRequired,
};

export default Description;
