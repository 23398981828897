import PropTypes from 'prop-types';
import { times } from 'underscore';

const Team = ({
  active,
  description,
  inPlay,
  redCards,
  score,
}) => (
  <span className="inplay-coupon-competition-team">
    <span className={`inplay-coupon-team-name ${active ? 'active' : ''}`}>
      {description}
    </span>
    <span className="inplay-coupon-card-wrapper">
      {
        times(redCards, (n) => (
          <span key={n} className="inplay-coupon-red-card" />
        ))
      }
    </span>
    {inPlay && (
      <span className="inplay-coupon-team-score">
        {score}
      </span>
    )}
  </span>
);

Team.propTypes = {
  active: PropTypes.bool,
  description: PropTypes.string.isRequired,
  inPlay: PropTypes.bool,
  redCards: PropTypes.number,
  score: PropTypes.number,
};

Team.defaultProps = {
  active: false,
  inPlay: false,
  redCards: null,
  score: null,
};

export default Team;
