import PropTypes from 'prop-types';
import { FormattedPrice } from 'SportsbookCommon';

const Price = ({ formattedPrice }) => (
  <div className="single-price option-column">
    <span className="odds-price">
      <FormattedPrice price={formattedPrice} />
    </span>
  </div>
);

Price.propTypes = {
  formattedPrice: PropTypes.string.isRequired,
};

export default Price;
