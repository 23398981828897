import singlePresenter from './single_presenter';
import multiplePresenter from './multiple_presenter';

export default ({
  showResponse,
  enhancedOutcomesById,
  stakes,
  usePromocash,
  betslipType,
  priceFormatId,
  outcomes,
  timeoutKey,
  singlesFilter,
  multiplesFilter,
}) => {
  const singles = showResponse.singles.reduce((acc, single) => {
    // ignore hidden or suspended outcomes
    const outcome = outcomes[single.outcomeId];
    // outcomes have .ms and .os as market and outcome statuses.
    // They are telling you the same thing:
    // if it's not 1 you can't place a bet, but .os is not updated in
    // all the backend pieces so better to check both fields.
    //
    // Hidden status should only matter if the .os field is non "1"
    // This field is now set by betslipShow, so it's always present
    //
    // Outcomes with lineDistance values should be submitted even if
    // the market is closed, backend will pick the right thing for the user
    if (outcome
      && !(Number.isInteger(outcome.outcomeLineDistance))
      && (((outcome.h || outcome.hidden) && outcome.os !== 1)
        || (outcome.os && outcome.os !== 1)
        || (outcome.ms && outcome.ms !== 1)
      )) {
      return acc;
    }

    const stake = stakes[`s-${single.outcomeId}`] || {};
    // if the current tab filters out singles
    // we need to pretend they were submitted with stake '0' in case
    // one of them was `sp_selected` because that affects
    // how multiples are placed
    stake.value = (singlesFilter(single) && stake.value) || '0';

    const { fractionalEnhancedOdds } = enhancedOutcomesById[single.outcomeId] || {};
    acc[single.outcomeId] = singlePresenter({
      single, stake, fractionalEnhancedOdds, betslipType,
    });
    return acc;
  }, {});

  const multiples = showResponse.multiples.reduce((acc, multiple) => {
    const stake = (multiplesFilter(multiple) && stakes[`m-${multiple.winBetTypeId}`]) || {};
    acc[multiple.winBetTypeId] = multiplePresenter({ multiple, stake, betslipType });
    return acc;
  }, {});

  const presented = {
    promocash: usePromocash,
    stakes: {
      singles,
      multiples,
    },
    price_format_id: priceFormatId,
    reset_betslip: true,
    timeout_key: timeoutKey,
  };

  return presented;
};
