/* eslint import/prefer-default-export: off */

import { createSelector } from 'reselect';

const getEvents = (state) => state.events;
const getEventId = (_, props) => props.eventId;

const makeGetEvent = () => createSelector(
  getEventId,
  getEvents,
  (eventId, events) => events[eventId],
);

export {
  getEvents,
  makeGetEvent,
};
