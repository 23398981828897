export default (direction) => (eventA, eventB) => {
  if (eventA.startTime === eventB.startTime) {
    return eventA.description >= eventB.description ? 1 : -1;
  }

  const sortValue = new Date(eventA.startTime) - new Date(eventB.startTime);

  return direction === 'DESC'
    ? sortValue
    : -sortValue;
};
