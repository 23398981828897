import { useMemo } from 'react';
import { isEqual } from 'underscore';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redux as EssentialScoresRedux } from 'sportsbook-essential-scores';
import ClockView from './clock_view';

const ClockRedux = ({ eventId, sportId }) => {
  // Stablish redux selector to extract the event info for the given event id
  const getEventScoreboard = useMemo(EssentialScoresRedux.selectors.makeGetEventScoreboard, []);
  const { clk } = useSelector((state) => getEventScoreboard(state, { eventId }), isEqual);
  // --------------------------------------------

  return (
    <ClockView
      sportId={sportId}
      clock={clk}
    />
  );
};

ClockRedux.propTypes = {
  eventId: PropTypes.number.isRequired,
  sportId: PropTypes.number.isRequired,
};

export default ClockRedux;
