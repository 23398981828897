import PropTypes from 'prop-types';
import EventDetailView from './event_detail_view';

const EventDetailContainer = ({
  event, market, showMarketDescription, badgeFilter,
}) => (
  <EventDetailView
    eventDescription={event.description}
    badges={(event.metadata ? (event.metadata.badges || []) : []).filter(badgeFilter)}
    marketDescription={(showMarketDescription && market?.desc) || ''}
    placeTermDescripton={market?.pltDes || ''}
  />
);

EventDetailContainer.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
  market: PropTypes.instanceOf(Object).isRequired,
  showMarketDescription: PropTypes.bool.isRequired,
  badgeFilter: PropTypes.func.isRequired,
};

export default EventDetailContainer;
