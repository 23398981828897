import { FETCH_COMPONENT_INIT, FETCH_COMPONENT_SUCCESS, REMOVE_COMPONENT } from './action_types';

export const initialState = {};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_COMPONENT_INIT:
      return {
        ...state,
        [action.payload.id]: { loading: true },
      };
    case FETCH_COMPONENT_SUCCESS:
      return {
        ...state,
        [action.payload.id]: action.payload.component,
      };
    case REMOVE_COMPONENT: {
      const { [action.payload.id]: _, ...newState } = state;

      return newState;
    }
    default:
      return state;
  }
};
