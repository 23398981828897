import { createSelector } from 'reselect';
import { getOutcomes } from 'sportsbook-outcomes-selector';
import makeGetEventMarket from './make_get_event_market';

export default () => createSelector(
  makeGetEventMarket(),
  getOutcomes,
  (market, outcomes) => (market
    ? market.o.map((outcomeId) => outcomes[outcomeId])
    : []),
);
