import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { EventIcons } from 'sportsbook-components';
import { DefaultClockInfo } from '../../clock_info';

const ShowMore = ({
  eventId, defaultInPlay, showClock, badges,
}) => (
  <span className="inplay-coupon-competition-more">
    { showClock && (
      <DefaultClockInfo
        eventId={eventId}
        defaultInPlay={defaultInPlay}
      />
    )}
    {' '}
    <span className="more">{t('javascript.more')}</span>
    <EventIcons badges={badges} />
  </span>
);

ShowMore.propTypes = {
  badges: PropTypes.instanceOf(Array).isRequired,
  showClock: PropTypes.bool.isRequired,
  eventId: PropTypes.number.isRequired,
  defaultInPlay: PropTypes.bool.isRequired,
};

export default ShowMore;
