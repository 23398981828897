import PropTypes from 'prop-types';
import { Link, Reversible } from 'bv-components';
import { v as bvVar } from 'bv';
import ShowMore from '../common/show_more';
import Team from '../common/team';

const EventDetailView = ({
  eventId,
  eventHref,
  opponentADescription,
  opponentBDescription,
  opponentARedCards,
  opponentBRedCards,
  opponentAScore,
  opponentBScore,
  opponentAActive,
  opponentBActive,
  showMore,
  badges,
  badgeFilter,
  inPlay,
  defaultInPlay,
  showClock,
  americanFormat,
}) => (
  <Link to={eventHref} className="inplay-coupon-competition-column">
    <Reversible reverse={americanFormat && bvVar('useAmericanFormat')}>
      <Team
        description={opponentADescription}
        active={opponentAActive}
        redCards={opponentARedCards}
        score={opponentAScore}
        inPlay={inPlay}
      />
      <Team
        description={opponentBDescription}
        active={opponentBActive}
        redCards={opponentBRedCards}
        score={opponentBScore}
        inPlay={inPlay}
      />
    </Reversible>
    {showMore && (
    <ShowMore
      showClock={showClock}
      defaultInPlay={defaultInPlay}
      eventId={eventId}
      badges={badges.filter(badgeFilter)}
    />
    )}
  </Link>
);

EventDetailView.propTypes = {
  americanFormat: PropTypes.bool.isRequired,
  eventHref: PropTypes.string.isRequired,
  opponentADescription: PropTypes.string.isRequired,
  opponentBDescription: PropTypes.string.isRequired,
  opponentARedCards: PropTypes.number.isRequired,
  opponentBRedCards: PropTypes.number.isRequired,
  opponentAScore: PropTypes.number.isRequired,
  opponentBScore: PropTypes.number.isRequired,
  opponentAActive: PropTypes.bool.isRequired,
  opponentBActive: PropTypes.bool.isRequired,
  showMore: PropTypes.bool.isRequired,
  badges: PropTypes.instanceOf(Array).isRequired,
  badgeFilter: PropTypes.func.isRequired,
  inPlay: PropTypes.bool.isRequired,
  eventId: PropTypes.number.isRequired,
  defaultInPlay: PropTypes.bool.isRequired,
  showClock: PropTypes.bool,
};

EventDetailView.defaultProps = {
  showClock: false,
};

export default EventDetailView;
