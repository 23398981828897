import PropTypes from 'prop-types';
import { EventIcons } from 'sportsbook-components';

const EventDetailView = ({
  eventDescription,
  marketDescription,
  placeTermDescripton,
  badges,
}) => (
  <div className="inplay-coupon-favcompetition-column">
    <span className="inplay-coupon-competition-team">
      <span className="inplay-coupon-team-name">
        {marketDescription && [
          marketDescription,
          <br />,
        ]}
        {eventDescription}
        <br />
        {placeTermDescripton}
      </span>
      {
          badges.length > 0 && (
            <div className="coupon-header-icons">
              <EventIcons badges={badges} />
            </div>
          )
        }
    </span>
  </div>
);
EventDetailView.propTypes = {
  eventDescription: PropTypes.string.isRequired,
  marketDescription: PropTypes.string.isRequired,
  placeTermDescripton: PropTypes.string,
  badges: PropTypes.instanceOf(Array).isRequired,
};

EventDetailView.defaultProps = {
  placeTermDescripton: '',
};

export default EventDetailView;
