import { addMiddleware } from 'redux-dynamic-middlewares';
import LuckyDipComponent from './components/lucky_dip_component';
import componentsReducer from './ducks/components/reducer';
import LuckyDipComponentsMiddleware from './ducks/components/middleware';
import luckyDipsReducer from './ducks/lucky_dips/reducer';
import LuckyDipsMiddleware from './ducks/lucky_dips/middleware';

const { addReducers } = window.reduxState;

addReducers({
  luckyDips: luckyDipsReducer,
  luckyDipComponents: componentsReducer,
});
addMiddleware(LuckyDipComponentsMiddleware);
addMiddleware(LuckyDipsMiddleware);

export default LuckyDipComponent;
