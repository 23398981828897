import { t } from 'bv-i18n';

export const getScoreboardHeaders = (scoreboard) => {
  // Darts
  if (scoreboard.sId === 965300) {
    return scoreboard && scoreboard.bos > 1 ? [
      t('javascript.darts.periods.sets_abrv'),
      t('javascript.darts.periods.legs_abrv'),
      t('javascript.darts.periods.points_abrv'),
    ] : [
      t('javascript.darts.periods.legs_abrv'),
      t('javascript.darts.periods.points_abrv'),
    ];
  }
  // Tennis and the rest of sets
  return [
    t('javascript.tennis.periods.sets_abrv'),
    t('javascript.tennis.periods.games_abrv'),
    t('javascript.tennis.periods.points_abrv'),
  ];
};

export default getScoreboardHeaders;
