import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'underscore';
import { useSelector } from 'react-redux';
import { makeGetMarket } from 'sportsbook-markets-selector';
import withEventDetailRedux from '../common/with_event_detail_redux';
import EventDetailContainer from './event_detail_container';

const EventDetailRedux = ({ eventId, marketId, ...rest }) => {
  const getMarket = useMemo(makeGetMarket, []);
  const market = useSelector((state) => getMarket(state, { marketId }), isEqual);

  return (
    <EventDetailContainer
      eventId={eventId}
      market={market}
      {...rest}
    />
  );
};

EventDetailRedux.propTypes = {
  eventId: PropTypes.number.isRequired,
  marketId: PropTypes.number,
};

EventDetailRedux.defaultProps = {
  marketId: null,
};

export default withEventDetailRedux(EventDetailRedux);
