import { getJSON, postJSON, deleteJSON } from 'bv-fetch';
import submitPresenter from './presenters/submit_presenter';
import betAddPresenter from './presenters/bet_add_presenter';
import multipleAddPresenter from './presenters/multiple_add_presenter';
import priceFetchPresenter from './presenters/price_fetch_presenter';

// force all prices to be in fractions,
// we do conversion on presentation later
const priceFormatId = 2;

export const FALLBACK_BET_DELAY = 5;
export const submitBetslipRequest = (params) => postJSON('/betslip/submit_betslip', submitPresenter({ ...params, priceFormatId }));
export const delayedBetStatusRequest = (params) => postJSON('/betslip/delayed_bet_status', submitPresenter({ ...params, priceFormatId }));
export const betslipFetch = () => getJSON(`/betslip.json?price_format_id=${priceFormatId}`);
export const addToBetslipRequest = (params) => postJSON('/betslip/bets', betAddPresenter({ ...params, priceFormatId }));
export const multipleAddToBetslipRequest = (params) => postJSON('/betslip/bets/add_multiple', multipleAddPresenter({ ...params, priceFormatId }));
export const removeFromBetslipRequest = (outcomeId) => deleteJSON(`/betslip/bets/${outcomeId}?price_format_id=${priceFormatId}`);
export const fetchPrices = (singles, multiples, updatedOutcome, shouldUpdatePrice) => getJSON('/betslip/bets/prices', priceFetchPresenter(singles, multiples, priceFormatId, updatedOutcome, shouldUpdatePrice));
export const followLineChangesRequest = (outcomeDistancePairs) => (
  getJSON('/betslip/follow_line_changes', { outcome_distance_pairs: outcomeDistancePairs, price_format_id: priceFormatId })
);
export const updatePriceRequest = (outcomeId, newPriceId) => getJSON('/betslip/bets/update_price', { outcome_id: outcomeId, price_id: newPriceId });
export const replaceSingle = (oldOutcomeId, newOutcomeId) => postJSON('/betslip/bets/replace_single', { old_outcome_id: oldOutcomeId, new_outcome_id: newOutcomeId, price_format_id: priceFormatId });

/* eslint import/prefer-default-export: 'off' */
