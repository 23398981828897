const enhancedOutcomeFields = (opts) => {
  if (!opts.enhancedOutcome) return {};

  return {
    fractional_enhanced_odds: opts.enhancedOutcome.fractionalEnhancedOdds || null,
    min_stake: opts.enhancedOutcome.minStake || null,
    max_stake: opts.enhancedOutcome.maxStake || null,
  };
};

export default ({
  outcome, market, opts, priceFormatId,
}) => (
  {
    bet: {
      outcome_id: outcome.id,
      price_id: outcome.prid,
      price: outcome.prd,
      place_terms_deduction: (
        outcome.place_terms_deduction || outcome.placeTermsDeduction || market.pltD || null
      ),
      place_terms_description: (
        outcome.place_terms_description || outcome.placeTermsDescription || market.pltDes || null
      ),
      price_text_formatted: outcome.formattedPrice,
      click_source: opts.clickSource,
      ...enhancedOutcomeFields(opts),
    },
    price_format_id: priceFormatId,
  }
);
