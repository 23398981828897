/* eslint no-nested-ternary: off */

import PropTypes from 'prop-types';
import EventCommentView from './event_comment_view';

// TODO: This is duplicated from the overview, unify when unifying the scorebooards
// and also extract to a better place
const messageTypeToClassName = {
  100: {
    goal: { messageClass: 'goal', iconClass: 'is-goal' },
    red_card: { messageClass: 'red-card', iconClass: 'is-card' },
    penalty: { messageClass: 'penalty', iconClass: 'is-penalty' },
    momentum_shot_on_target: { messageClass: 'dangerous-attack', iconClass: 'is-shot-on-target' },
  },
  601600: {
    timeout: { messageClass: 'time-out', iconClass: 'is-time-out' },
    momentum_points: { messageClass: '', iconClass: 'is-basket-score' },
  },
  600: {
    break_point: { messageClass: 'break-point', iconClass: 'is-tennis' },
    set_point: { messageClass: 'break-point', iconClass: 'is-tennis' },
    match_point: { messageClass: 'break-point', iconClass: 'is-tennis' },
    tiebreak_about_to_begin: { messageClass: 'break-point', iconClass: 'is-tennis' },
    rain_delay: { messageClass: '', iconClass: 'is-tennis' },
    trainer_called: { messageClass: '', iconClass: 'is-tennis' },
    injury: { messageClass: '', iconClass: 'is-tennis' },
    medical_timeout: { messageClass: '', iconClass: 'is-tennis' },
    toilet_break: { messageClass: '', iconClass: 'is-tennis' },
    momentum_point: { messageClass: '', iconClass: 'is-tennis' },
    momentum_game_winner: { messageClass: '', iconClass: 'is-tennis' },

  },
};

const nullClasses = {
  messageClass: '',
  iconClass: '',
};

const getClassName = (messageType, sportId) => {
  const type = (messageType || '').toLowerCase();
  const sport = messageTypeToClassName[sportId] || {};
  return (sport[type] || nullClasses);
};

// --------------------------------------------------------

const EventCommentContainer = ({ event, comment }) => {
  if (!comment) return null;

  const {
    sportId,
    oppAId,
    oppADesc,
    oppBDesc,
  } = event;
  const { messageType, opp, comm } = comment;

  const { messageClass, iconClass } = getClassName(
    messageType,
    sportId,
  );

  const teamName = opp
    ? opp === oppAId ? oppADesc : oppBDesc
    : '';

  return (
    <EventCommentView
      comment={comm}
      teamName={teamName}
      messageClass={messageClass}
      iconClass={iconClass}
    />
  );
};

EventCommentContainer.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
  comment: PropTypes.instanceOf(Object),
};

EventCommentContainer.defaultProps = {
  comment: null,
};

export default EventCommentContainer;
