import { v as bvVar } from 'bv';

const intersection = (a, b) => a.filter((x) => b.includes(x));
const homeAwayOutcomeKeys = ['HOME', 'AWAY'];

export default (americanFormat, outcomes) => (
  americanFormat
    && bvVar('useAmericanFormat')
    && intersection(homeAwayOutcomeKeys, outcomes.map((o) => o.keyDimension)).length === 2
);
