import PropTypes from 'prop-types';
import { Icon, Link } from 'bv-components';
import { t } from 'bv-i18n';

const Header = ({ action: href, title }) => (
  <div className="ld-header-wrapper">
    <h2 className="bvs-header bvs-ellipsis">
      <Icon className="bvs-event-icon is-lucky-dip" big />
      {title || t('lucky_dip.component.default_title')}
    </h2>
    {href && (
      <Link to={href}>
        {t('lucky_dip.component.how_to_play')}
        <Icon className="ld-how-to-play" />
      </Link>
    )}
  </div>
);

Header.propTypes = {
  action: PropTypes.string,
  title: PropTypes.string.isRequired,
};

Header.defaultProps = {
  action: null,
};

export default Header;
