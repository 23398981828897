import { createSelector } from 'reselect';
import getEvents from './get_events';

const getPropsEventIds = (_, props) => props.eventIds;

export default () => createSelector(
  getPropsEventIds,
  getEvents,
  (eventIds, events) => eventIds.map((eventId) => events[eventId]),
);
