import { ADD_TO_BETSLIP_INIT, hideMiniBetslip } from 'sportsbook-betslip-duck';
import { resetSelectedOutcomes, SELECT_OUTCOME } from 'EventLevel/components/coupons/betbuilder/duck';
import { resetBetslip } from './ducks/action_creators';
import { ADD_TO_BETSLIP } from './ducks/action_types';

export default ({ dispatch, getState }) => (next) => (action) => {
  switch (action.type) {
    case ADD_TO_BETSLIP_INIT:
    case SELECT_OUTCOME:
      dispatch(resetBetslip());
      break;
    case ADD_TO_BETSLIP:
      if (getState().betslip.showMiniBetslip) {
        dispatch(hideMiniBetslip());
      }
      dispatch(resetSelectedOutcomes());
      break;
    default:
      break;
  }

  next(action);
};
