import Default from './default/event_detail_redux';
import Sets from './sets/event_detail_redux';
import Outright from './outright/event_detail_redux';

export default (template, sportId) => {
  const isOutright = /template_c/.test(template);
  // Darts and Tennis always show sets scoreboard
  if (!isOutright && [965300, 600].includes(sportId)) {
    return Sets;
  }

  switch (template) {
    case 'template_c':
    case 'template_c3':
      return Outright;
    case 'template_d':
      return Sets;
    default:
      return Default;
  }
};
