import PropTypes from 'prop-types';
import { Link } from 'bv-components';
import ShowMore from '../common/show_more';
import Team from '../common/team';

const EventDetailView = ({
  badges,
  description,
  eventHref,
  showMore,
  showClock,
}) => (
  <Link to={eventHref} className="inplay-coupon-competition-column">
    <Team description={description} />
    {showMore && <ShowMore showClock={showClock} badges={badges} />}
  </Link>
);

EventDetailView.propTypes = {
  badges: PropTypes.instanceOf(Array).isRequired,
  description: PropTypes.string.isRequired,
  eventHref: PropTypes.string.isRequired,
  showMore: PropTypes.bool.isRequired,
  showClock: PropTypes.bool.isRequired,
};

export default EventDetailView;
