import { registerHandler, unregisterHandler } from 'event-bus';
import { updateLuckyDip } from '../ducks/lucky_dips/action_creators';

const luckyDipUpdateHandler = (_, { body }) => {
  const { store } = window.reduxState;

  store.dispatch(updateLuckyDip(body));
};

export const register = ({ notificationsAddress }) => {
  registerHandler(notificationsAddress, luckyDipUpdateHandler);
};

export const unregister = ({ notificationsAddress }) => {
  unregisterHandler(notificationsAddress, luckyDipUpdateHandler);
};
