import { register, unregister } from '../../helpers/event_bus';
import { ADD_LUCKY_DIPS, REMOVE_LUCKY_DIPS } from './action_types';

const lastInState = (luckyDip) => luckyDip.refCount === 1;

const luckyDipsMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case ADD_LUCKY_DIPS: {
      const state = store.getState();

      Object.values(action.payload.luckyDips)
        .filter(({ id }) => !state.luckyDips[id])
        .forEach(register);
      break;
    }

    case REMOVE_LUCKY_DIPS: {
      const state = store.getState();

      action.payload.ids
        .map((id) => state.luckyDips[id])
        .filter(Boolean)
        .filter(lastInState)
        .forEach(unregister);
      break;
    }

    default:
      break;
  }

  next(action);
};

export default luckyDipsMiddleware;
