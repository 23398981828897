import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, RenderInBody, Spinner } from 'bv-components';
import { t } from 'bv-i18n';
import { useFetch, useLockBodyScroll } from 'bv-hooks';
import { fetchLuckyDipSelections } from '../api';

const SelectionsModal = ({ luckyDipId, onClose }) => {
  const [selections, loading] = useFetch(() => fetchLuckyDipSelections(luckyDipId), [luckyDipId]);
  const scrollableRef = useRef();

  useLockBodyScroll(scrollableRef);

  const outcomeDescriptions = selections?.outcomeDescriptions || [];

  return (
    <RenderInBody>
      <Modal
        className="ld-modal-outcomes"
        title={loading ? '' : t('lucky_dip.component.selections_modal.title', { count: outcomeDescriptions.length })}
        onCloseClick={onClose}
        icon={false}
        iconClosable
      >
        <div className="has-scroll-bars ld-modal-outcomes__list" ref={scrollableRef}>
          {loading
            ? <Spinner />
            : outcomeDescriptions.map((desc) => (<p key={desc}>{desc}</p>))}
        </div>
      </Modal>
    </RenderInBody>
  );
};

SelectionsModal.propTypes = {
  luckyDipId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SelectionsModal;
