import { createSelector } from 'reselect';
import { BETSLIP_STATUS } from './duck';

const getStakeId = (bet) => (bet.outcomeId ? `s-${bet.outcomeId}` : `m-${bet.winBetTypeId}`);

const getCashoutBetNumber = (state) => state?.floatingNavigation?.cashoutBetsNumber || 0;

const getBetslipInited = (state) => state.betslip.inited;
const getBetslipSubmitId = (state) => state.betslip.submitId;
const getBetslipOutcomeIds = (state) => state.betslip.outcomeIds;
const getShowMiniBetslip = (state) => state.betslip.showMiniBetslip;
const getStatus = (state) => state.betslip.status;
const getBetslipIsSubmitting = (state) => (
  [BETSLIP_STATUS.DELAY, BETSLIP_STATUS.SUBMIT].includes(state.betslip.status)
);
const getBetDelay = (state) => state.betslip.betDelay;
const getPreviousOutcomeIds = (state) => state.betslip.previousOutcomeIds || [];
const getSubmitResponse = (state) => (state.betslip.submitResponse || {});
const getRemoveStakeForBonusBet = (state) => (
  (state.betslip.showResponse || {}).removeStakeForBonusBet || false
);
const getAcceptPriceChanges = (state) => state.acceptAllPriceChangesToggle || {};
const getAcceptLineChanges = (state) => state.acceptAllLineChangesToggle || {};
const getBetslipReference = (state) => (
  (state.betslip.showResponse || {}).betslipReference || ''
);
const getAcceptAllPriceChangesToggleLoading = createSelector(
  [getAcceptPriceChanges],
  (acceptPriceChanges) => acceptPriceChanges.loading,
);
const getAcceptAllPriceChangeToggleValue = createSelector(
  [getAcceptPriceChanges],
  (acceptPriceChanges) => acceptPriceChanges.value,
);
const getAcceptAllLineChangeToggleValue = createSelector(
  [getAcceptLineChanges],
  (acceptLineChanges) => acceptLineChanges.value,
);
const getOutcomeId = (state, props) => props.id || props.outcome_id || props.outcomeId;
const getOutcomes = (state) => state.outcomes;

const getBetslipSingles = (state) => ((state.betslip.showResponse || {}).singles || []);
const getFirstBetslipSingle = (state) => ((state.betslip.showResponse || {}).singles || [])[0];
const getBetslipSingleOutcomeIds = (state) => (getBetslipSingles(state).map((s) => s.outcomeId));

const getBetslipMultiples = (state) => ((state.betslip.showResponse || {}).multiples || []);
const getBetslipBets = (state) => ([...getBetslipSingles(state), ...getBetslipMultiples(state)]);

const getBetIssue = (state, stakeId) => ((state.betslip.betIssues || {})[stakeId] || {});

const getStakes = (state) => state.betslip.stakes || [];
const getStake = (state, stakeId) => (getStakes(state)[stakeId] || { value: '', ews: false, sp: false });
const hasAnyBonusEligibleBet = (state) => (getBetslipBets(state)
  .some((bet) => bet.bonusFundsEligible));
const hasAnyStakes = (state) => (
  getBetslipBets(state)
    .map((bet) => getStake(state, getStakeId(bet)))
    .some((stake) => (stake.value !== '' && stake.value !== '0'))
);
const isAllBonusEligible = (state) => (
  getBetslipBets(state).every((bet) => {
    const stake = getStake(state, getStakeId(bet));
    // either has no stake, or it needs to be eligible
    return stake.value === '' || stake.value === '0' || bet.bonusFundsEligible;
  })
);

const getUsePromocash = (state) => state.betslip.usePromocash && isAllBonusEligible(state);

const getTaxDeductPrc = (state) => ((getBetslipBets(state)[0] || {}).taxDeductPrc) || 0;

const getCurrentTab = (state) => state.betslip.currentTab;
const getMultiplesExpanded = (state) => state.betslip.multiplesExpanded;

// Selector used for multiple outcomes where each one will receive their
// own props. In order to memoize properly we're returning a function that
// create a selector instead of the selector so every Outcome component
// can have its own private copy of the selector
// https://github.com/reactjs/reselect#sharing-selectors-with-props-across-multiple-components
const makeOutcomeInBetslip = () => createSelector(
  [getBetslipOutcomeIds, getOutcomeId],
  (outcomeIds, outcomeId) => outcomeIds.includes(outcomeId),
);

const makeGetSingle = () => createSelector(
  [getBetslipSingles, getOutcomeId],
  (singles, outcomeId) => singles.find((s) => s.outcomeId === outcomeId),
);

const makeGetBetslipOutcomes = () => createSelector(
  [getBetslipSingleOutcomeIds, getOutcomes],
  (outcomeIds, outcomes) => outcomeIds.map((outcomeId) => outcomes[outcomeId]).filter((o) => o),
);

export {
  getCashoutBetNumber,

  getBetslipInited,
  getBetslipOutcomeIds,
  getShowMiniBetslip,
  getStatus,
  getBetslipSubmitId,
  getBetslipIsSubmitting,
  getSubmitResponse,
  getBetDelay,
  getUsePromocash,
  getPreviousOutcomeIds,
  getAcceptAllPriceChangesToggleLoading,
  getAcceptAllPriceChangeToggleValue,
  getAcceptAllLineChangeToggleValue,
  getRemoveStakeForBonusBet,
  getBetslipReference,
  getBetslipSingleOutcomeIds,
  getBetslipSingles,
  getFirstBetslipSingle,
  getBetIssue,
  hasAnyStakes,
  hasAnyBonusEligibleBet,
  isAllBonusEligible,
  getTaxDeductPrc,
  getCurrentTab,

  makeOutcomeInBetslip,
  makeGetBetslipOutcomes,
  makeGetSingle,
  getBetslipMultiples,
  getMultiplesExpanded,

  getStakes,
  getStake,
};
