import PropTypes from 'prop-types';

const Team = ({ active, description, scores }) => (
  <span className="inplay-coupon-competition-team">
    <span className={`inplay-coupon-team-name ${active ? 'active' : ''}`}>
      {description}
    </span>
    <span className={`inplay-coupon-score-row inplay-coupon-header-set-scores ${active ? 'active' : ''}`}>
      {scores.map((s) => <span className="inplay-coupon-score">{s}</span>)}
    </span>
  </span>
);

Team.propTypes = {
  active: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  scores: PropTypes.instanceOf(Array).isRequired,
};

export default Team;
