import { createSelector } from 'reselect';

const getEventsMarkets = (state) => state['sportsbook/overview'].eventsActiveMarket || {};
const getPropsEventId = (_, props) => props.eventId;

export default () => createSelector(
  getEventsMarkets,
  getPropsEventId,
  (eventsMarkets, eventId) => (eventsMarkets[eventId] || []),
);
