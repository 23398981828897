import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEqual, isEmpty } from 'underscore';
import { makeGetEvent } from 'sportsbook-events-selector';
import { Redux as EssentialScoresRedux } from 'sportsbook-essential-scores';
import ClockInfoContainer from './clock_info_container';

const ClockInfoRedux = ({
  eventId,
  defaultInPlay,
}) => {
  // Stablish redux selector to extract the event for the given event id
  const getEvent = useMemo(makeGetEvent, []);
  const event = useSelector((state) => getEvent(state, { eventId }), isEqual);
  // --------------------------------------------

  // Stablish redux selector to extract the scoreboard for the event
  const getEventScoreboard = useMemo(
    EssentialScoresRedux.selectors.makeGetEventNoClockScoreboard,
    [],
  );
  const scoreboard = useSelector((state) => getEventScoreboard(state, { eventId }), isEqual);
  // --------------------------------------------

  if (!event || !scoreboard) return null;

  const inPlay = defaultInPlay || event.inPlay;

  return (
    <ClockInfoContainer
      eventId={event.id}
      sportId={event.sportId}
      inPlay={inPlay && !isEmpty(scoreboard) && !scoreboard.disabledScoreboard}
    />
  );
};

ClockInfoRedux.propTypes = {
  eventId: PropTypes.number.isRequired,
  defaultInPlay: PropTypes.bool,
};

ClockInfoRedux.defaultProps = {
  defaultInPlay: undefined,
};

export default ClockInfoRedux;
