import PropTypes from 'prop-types';

const Period = ({ scoreboard }) => (
  <span className="inplay-coupon-period">
    {scoreboard.dsc}
    {scoreboard.gDsc && ` ${scoreboard.gDsc}` }
  </span>
);

Period.propTypes = {
  scoreboard: PropTypes.instanceOf(Object).isRequired,
};

export default Period;
