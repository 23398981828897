import PropTypes from 'prop-types';
import ClockInfoView from './clock_info_view';
import { Clock } from './clock';
import { StartTime } from './start_time';
import { Period } from './period';

const ClockInfoContainer = ({ eventId, sportId, inPlay }) => (
  <ClockInfoView
    isInplay={inPlay}
    startTimeComponent={<StartTime eventId={eventId} />}
    clockComponent={<Clock eventId={eventId} sportId={sportId} />}
    periodComponent={<Period eventId={eventId} />}
  />
);

ClockInfoContainer.propTypes = {
  sportId: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
  inPlay: PropTypes.bool.isRequired,
};

export default ClockInfoContainer;
