import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { PromocashToggle as PromocashToggleView } from 'sportsbook-betslip-components';
import { toggleUsePromocash as toggleUsePromocashAction } from '../ducks/action_creators';
import { selectUsePromocash } from '../ducks/selectors';

const PromocashToggle = ({ bonusFundsEligible }) => {
  const usePromocash = useSelector(selectUsePromocash);
  const dispatch = useDispatch();
  const toggleUsePromocash = () => dispatch(toggleUsePromocashAction());

  useEffect(() => {
    if (usePromocash && !bonusFundsEligible) {
      toggleUsePromocash();
    }
  }, [bonusFundsEligible, usePromocash]);

  if (!bonusFundsEligible) return null;

  return (
    <div className="betslip__details">
      <div className="betslip__details__toggles">
        <PromocashToggleView
          usePromocash={usePromocash}
          onToggle={toggleUsePromocash}
          disabled={false}
        />
      </div>
    </div>
  );
};

PromocashToggle.propTypes = {
  bonusFundsEligible: PropTypes.bool.isRequired,
};

export default PromocashToggle;
