import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { hideMiniBetslip } from '../betslip/duck';
import { clearBetslip } from '../betslip/thunks';

const {
  RightSidebar,
  reduxState: { store: { getState, dispatch } },
} = window;

export default () => {
  const { betslip: { submitResponse } } = getState();
  dispatch(hideMiniBetslip());
  RightSidebar.hide();
  clearAllBodyScrollLocks();
  if (submitResponse && submitResponse.success) dispatch(clearBetslip());
};
