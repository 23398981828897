export default (rawSingle) => ({
  clickSource: rawSingle.click_source,
  outcomeId: rawSingle.outcome_id,
  outcomeDescription: rawSingle.outcome_description,
  periodDescription: rawSingle.period_description,
  marketTypeDescription: rawSingle.market_description,
  meetingDescription: rawSingle.meeting_description,
  eventDescription: rawSingle.event_description,
  eventId: rawSingle.event_id,
  priceId: rawSingle.price_id,
  originalPriceId: rawSingle.original_price_id || rawSingle.price_id,
  decimalPrice: rawSingle.price,
  originalDecimalPrice: rawSingle.price,
  textFormattedPrice: rawSingle.price_text_formatted,
  originalTextFormattedPrice: rawSingle.price_text_formatted,
  hasEW: rawSingle.ew,
  oddsEW: rawSingle.odds_ew,
  originalOddsEW: rawSingle.odds_ew,
  placeTermsDescription: rawSingle.place_terms_description,
  placeTermsDeduction: rawSingle.place_terms_deduction,
  sportId: rawSingle.sport_id,
  inRunning: rawSingle.in_running,
  eachWayBetTypeId: rawSingle.each_way_bet_type_id,
  bonusFundsEligible: rawSingle.bonus_funds_eligible,
  taxDeductPrc: rawSingle.tax_deduct_prc,
  spAllowed: rawSingle.spallowed,
  winBetTypeId: 1, // just a default we always have, used for price-change-validations
  fractionalEnhancedOdds: rawSingle.enhanced_odds_text_formatted,
  decimalEnhancedOdds: rawSingle.enhanced_odds,
  minStake: rawSingle.min_stake,
  maxStake: rawSingle.max_stake,
  predefinedStake: rawSingle.predefined_stake,
  ewAllowed: rawSingle.ew_allowed,
  outcomeLegs: rawSingle.outcome_legs,
  outcomeKey: rawSingle.outcome_key,
  outcomeLineDistance: rawSingle.outcome_line_distance,
  originalOutcomeLineDistance: rawSingle.outcome_line_distance,
  marketTypeId: rawSingle.market_type_id,
  marketId: rawSingle.market_id,
  lineFollowed: rawSingle.line_followed,
  timestamp: rawSingle.outcome_written,
});
