import { get as localStorageGet } from 'bv-local-storage';

export const LOCAL_STORAGE_KEY = 'overview-sorting';
export const DEFAULT_DIRECTION = 'DESC';

export const initialMethod = (sortingMethods) => {
  const userSorting = localStorageGet(LOCAL_STORAGE_KEY);

  return (userSorting && sortingMethods.find((s) => s.id === userSorting.method))
    || sortingMethods.find((s) => s.default)
    || sortingMethods[0];
};

export const initialDirection = () => {
  const userSorting = localStorageGet(LOCAL_STORAGE_KEY);

  return (userSorting && userSorting.direction) || DEFAULT_DIRECTION;
};

export default (sortingMethods) => ({
  method: initialMethod(sortingMethods).id,
  direction: initialDirection(),
});
