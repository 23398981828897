import { compact } from 'underscore';
import { createSelector } from 'reselect';
import { getEvents } from 'sportsbook-events-selector';
import getEventIds from './get_event_ids';

export default createSelector(
  getEvents,
  getEventIds,
  (events, eventIds) => compact(eventIds.map((eventId) => events[eventId]))
    .reduce((acc, event) => ({
      ...acc,
      [event.id]: event,
    }), {}),
);
