export const fillArrayToLength = (array, limit) => (array.length
  ? Array(limit).fill().map((_, i) => array[i % array.length])
  : []);

export const truncate = (text, limit) => {
  const ellipsis = '...';
  return text.length > limit
    ? `${text.slice(0, limit - ellipsis.length)}${ellipsis}`
    : text;
};
