import PropTypes from 'prop-types';

const EventCommentView = ({
  teamName,
  comment,
  messageClass,
  iconClass,
}) => (
  <div className="inplay-momentum-wrapper momentum-anim-play">
    <span className="inplay-momentum-title">{teamName}</span>
    <span className={`inplay-momentum-event ${messageClass}`}>{comment}</span>
    <span className={`inplay-momentum-icon ${iconClass}`} />
  </div>
);

EventCommentView.propTypes = {
  teamName: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  messageClass: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
};

export default EventCommentView;
