import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import { makeGetEvent } from 'sportsbook-events-selector';
import { Redux as EssentialScoresRedux } from 'sportsbook-essential-scores';
import EventCommentContainer from './event_comment_container';

const EventCommentRedux = ({ eventId }) => {
  // Stablish redux selector to extract the event for the given event id
  const getEvent = useMemo(makeGetEvent, []);
  const event = useSelector((state) => getEvent(state, { eventId }), isEqual);
  // --------------------------------------------

  // Stablish redux selector to extract the active comment for the eventId
  const { selectors: { makeGetEventMostRecentComment } } = EssentialScoresRedux;
  const getEventMostRecentComment = useMemo(makeGetEventMostRecentComment, []);
  const comment = useSelector((state) => getEventMostRecentComment(state, { eventId }), isEqual);
  // --------------------------------------------

  return (
    <EventCommentContainer
      event={event}
      comment={comment}
    />
  );
};

EventCommentRedux.propTypes = {
  eventId: PropTypes.number.isRequired,
};

export default EventCommentRedux;
