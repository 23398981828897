import sortMethod from './common/sort_method';
import compareFn from './compare_fns/time';

export default {
  id: 'time',
  default: false,
  applicable: () => true,
  title: 'javascript.overview_sorting.time',
  sort: sortMethod(compareFn),
};
