import { REMOVE_COMPONENT } from './action_types';
import { removeLuckyDips } from '../lucky_dips/action_creators';

const componentsMiddleware = ({ getState, dispatch }) => (next) => (action) => {
  switch (action.type) {
    case REMOVE_COMPONENT: {
      const { luckyDips } = getState().luckyDipComponents[action.payload.id];
      dispatch(removeLuckyDips(luckyDips));
      break;
    }

    default:
      break;
  }

  next(action);
};

export default componentsMiddleware;
