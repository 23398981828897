import PropTypes from 'prop-types';
import { Carousel } from 'bv-components';
import LuckyDip from './lucky_dip';

const LuckyDips = ({ luckyDips, showEventDescription }) => (
  <Carousel scrollStep={284} largeArrows className="ld-card-wrapper">
    {luckyDips.map((luckyDip) => (
      <LuckyDip
        key={luckyDip.id}
        luckyDip={luckyDip}
        showEventDescription={showEventDescription}
      />
    ))}
  </Carousel>
);

LuckyDips.propTypes = {
  luckyDips: PropTypes.arrayOf(Object).isRequired,
  showEventDescription: PropTypes.bool.isRequired,
};

export default LuckyDips;
