import { flatten } from 'underscore';
import { createSelector } from 'reselect';
import getCards from './get_cards';
import getGroupsByCard from './get_groups_by_card';

export default createSelector(
  getCards,
  getGroupsByCard,
  (cards, groupsByCard) => flatten(
    cards
      .filter((card) => card.expanded)
      .map((card) => (groupsByCard[card.id] || []).map(((group) => group.children))),
  ),
);
