import { BETSLIP_STATUS } from 'Sportsbook/betslip/duck';
import {
  ADD_TO_BETSLIP,
  BETSLIP_SUBMIT_COMPLETE,
  BETSLIP_SUBMIT_INIT,
  HIDE_BETSLIP,
  RESET_BETSLIP,
  SET_STAKE,
  SHOW_BETSLIP,
  TOGGLE_USE_PROMO_CASH,
} from './action_types';

export const initialState = {
  luckyDipId: null,
  stake: '',
  submitResponse: null,
  usePromocash: false,
  isVisible: false,
  status: BETSLIP_STATUS.READY,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_TO_BETSLIP:
      return state.status === BETSLIP_STATUS.READY ? {
        ...state,
        luckyDipId: action.payload.luckyDip.id,
        isVisible: true,
      } : state;
    case RESET_BETSLIP:
      return {
        ...initialState,
      };
    case SET_STAKE:
      return {
        ...state,
        stake: action.payload.stake,
      };
    case TOGGLE_USE_PROMO_CASH:
      return {
        ...state,
        usePromocash: !state.usePromocash,
      };
    case BETSLIP_SUBMIT_INIT:
      return {
        ...state,
        status: BETSLIP_STATUS.SUBMIT,
      };
    case BETSLIP_SUBMIT_COMPLETE: {
      const { submitResponse } = action.payload;
      return {
        ...(submitResponse.success ? initialState : state),
        luckyDipId: state.luckyDipId,
        submitResponse,
        status: BETSLIP_STATUS.READY,
      };
    }
    case SHOW_BETSLIP:
      return {
        ...state,
        isVisible: true,
      };
    case HIDE_BETSLIP:
      return {
        ...state,
        isVisible: false,
      };
    default:
      return state;
  }
};
