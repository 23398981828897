import { createSelector } from 'reselect';
import { makeGetMarket } from 'sportsbook-markets-selector';

const getOutcomes = (state) => state.outcomes;

export default () => createSelector(
  makeGetMarket(),
  getOutcomes,
  (market, outcomes) => (market || { o: [] }).o
    .map((outcomeId) => outcomes[outcomeId])
    .map((outcome) => outcome.id),
);
