import { createSelector } from 'reselect';
import { isOpen } from 'SportsbookComponents/lucky_dip/lucky_dip_betslip/helpers';
import { selectLuckyDips } from '../lucky_dips/selectors';

const selectComponents = (state) => state.luckyDipComponents;
const getComponentId = (_, componentId) => componentId;

export const makeSelectComponentById = () => createSelector(
  [selectComponents, getComponentId],
  (components, componentId) => components[componentId],
);

export const makeSelectLuckyDips = () => createSelector(
  [makeSelectComponentById(), selectLuckyDips],
  (component, luckyDips) => (component?.luckyDips || [])
    .map((id) => luckyDips[id])
    .filter(isOpen),
);
