import PropTypes from 'prop-types';

const CloseButton = ({ onClick }) => (
  <button
    className="betslip-single__close-button bvs-icon is-close"
    type="button"
    onClick={onClick}
  />
);

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CloseButton;
