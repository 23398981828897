/* eslint no-undef: off */
import PropTypes from 'prop-types';

const StartTime = ({ time }) => (time ? (
  <span className="inplay-coupon-clock">
    {new Date(time).toLocaleString(BvApi.i18n.locale(), {
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h23',
    })}
  </span>
) : null);

StartTime.propTypes = {
  time: PropTypes.string.isRequired,
};

export default StartTime;
