import { decimalPrice } from 'bv';

const getFractionalEnhancedOdds = (fractionalEnhancedOdds) => {
  if (!fractionalEnhancedOdds) return {};

  return {
    fractional_enhanced_odds: fractionalEnhancedOdds,
    enhanced_odds: decimalPrice(fractionalEnhancedOdds),
  };
};

export default ({
  single, stake, fractionalEnhancedOdds, betslipType,
}) => ({
  s: (stake.value && stake.value.replace(',', '.')) || '0',
  ews: stake.ews || false,
  sp_selected: stake.sp || false,
  outcome_id: single.outcomeId,
  price_id: single.priceId,
  price: single.decimalPrice,
  ew: single.hasEW,
  place_terms_deduction: single.placeTermsDeduction,
  place_terms_description: single.placeTermsDescription,
  click_source: single.clickSource,
  price_text_formatted: single.textFormattedPrice,
  quick_bet: betslipType === 'mini-betslip',
  ...getFractionalEnhancedOdds(fractionalEnhancedOdds),
  outcome_line_distance: single.outcomeLineDistance,
});
