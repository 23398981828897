import sortMethod from './common/sort_method';
import compareFn from './compare_fns/popularity';

export default {
  id: 'popularity',
  default: false,
  applicable: () => true,
  title: 'javascript.overview_sorting.popularity',
  sort: sortMethod(compareFn),
};
