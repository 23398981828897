import { outcomePricePairs } from '../helpers';

const betTypeIds = (bets) => (
  Array.from(new Set(
    bets.flatMap((bet) => ([
      bet.winBetTypeId,
      bet.eachWayBetTypeId,
    ]))
      .filter(Boolean),
  ))
);

export default (singles, multiples, priceFormatId, updatedOutcome, shouldUpdatePrice) => ({
  betTypeIds: betTypeIds([...singles, ...multiples]),
  outcomePricePairs: outcomePricePairs(singles),
  priceFormatId,
  updatedOutcome,
  shouldUpdatePrice,
});
