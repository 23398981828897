import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import { makeGetEvent } from 'sportsbook-events-selector';
import StartTimeView from './start_time_view';

const ClockInfoRedux = ({ eventId }) => {
  // Stablish redux selector to extract the event for the given event id
  const getEvent = useMemo(makeGetEvent, []);
  const { startTime } = useSelector((state) => getEvent(state, { eventId }), isEqual);
  // --------------------------------------------

  return (
    <StartTimeView time={startTime} />
  );
};

ClockInfoRedux.propTypes = {
  eventId: PropTypes.number.isRequired,
};

export default ClockInfoRedux;
