import { getStakeValue } from 'Betslip/helpers';

export default ({
  luckyDip,
  stake,
  usePromocash,
}) => ({
  lucky_dip_id: luckyDip.id,
  sport_id: luckyDip.sportId,
  stake: getStakeValue({ value: stake }),
  use_bonus_funds: usePromocash,
});
