import { truncate } from './utils';

const TEXT_LIMIT = 36;
const TEXT_LIMIT_NO_RESIZE = 24;
const DEFAULT_FONT_SIZE = 60;
const MIN_FONT_SIZE = 38;
const EXP_DECAY_RATE = -3;

const calculateFontSize = (text) => {
  if (text.length <= TEXT_LIMIT_NO_RESIZE) return DEFAULT_FONT_SIZE;

  const ratio = (text.length - TEXT_LIMIT_NO_RESIZE) / (TEXT_LIMIT - TEXT_LIMIT_NO_RESIZE);
  const sizeReduction = (DEFAULT_FONT_SIZE - MIN_FONT_SIZE)
    * (1 - Math.exp(EXP_DECAY_RATE * ratio));

  return Math.round(DEFAULT_FONT_SIZE - sizeReduction);
};

export const formatText = (text) => {
  const truncatedText = truncate(text, TEXT_LIMIT);

  return {
    t: truncatedText,
    s: calculateFontSize(truncatedText),
  };
};
