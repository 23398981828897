export const FAKE_PRICE_ID = -1;

// To trigger hasPriceChanged from
// app/javascript/src/app/sportsbook/betslip/helpers.js
const insertFakePriceId = (singles, outcomeId) => (
  singles.map((single) => {
    if (single.outcome_id === outcomeId) {
      return {
        ...single,
        original_price_id: FAKE_PRICE_ID,
      };
    }

    return single;
  })
);

export default insertFakePriceId;
