import PropTypes from 'prop-types';
import { showClock } from 'SportsbookCommon/overview/clock_info/helpers';

const ClockView = ({ clock, sportId }) => (
  <span className="inplay-coupon-clock">
    {showClock(clock, sportId) && clock}
  </span>
);

ClockView.propTypes = {
  sportId: PropTypes.number.isRequired,
  clock: PropTypes.string.isRequired,
};

export default ClockView;
