import { createSelector } from 'reselect';
import getEvents from './get_events';
import getGroupsByCard from './get_groups_by_card';

const propsCardId = (_, props) => props.cardId;

export default () => createSelector(
  getGroupsByCard,
  getEvents,
  propsCardId,
  (groups, events, cardId) => (groups[cardId] || [])
    .reduce((acc, group) => [...acc, ...group.children], [])
    .map((eventId) => events[eventId])
    .filter((event) => event.inPlay)
    .map((inPlayEvent) => inPlayEvent.id),
);
