import { isEqual, omit, flatten } from 'underscore';

const allGroupEvents = (group, events) => flatten(
  group.map(
    (groupItem) => groupItem.children.map((eventId) => ({ ...events[eventId], groupItem })),
  ),
);

const eventGroupsReducer = (acc, event) => {
  const previousGroupItem = acc[acc.length - 1];

  if (acc.length === 0) {
    return [
      {
        ...event.groupItem,
        children: [event.id],
      },
    ];
  }

  if (isEqual(omit(previousGroupItem, 'children'), omit(event.groupItem, 'children'))) {
    return [
      ...acc.slice(0, acc.length - 1),
      {
        ...previousGroupItem,
        children: [
          ...previousGroupItem.children,
          event.id,
        ],
      },
    ];
  }

  return [
    ...acc,
    {
      ...event.groupItem,
      children: [event.id],
    },
  ];
};

const sortGroup = (group, events, compareFn, direction) => allGroupEvents(group, events)
  .sort(compareFn(direction))
  .reduce(eventGroupsReducer, []);

export default (compareFn) => (groupsByCard, events, direction) => Object.keys(groupsByCard)
  .reduce((acc, cardId) => {
    const group = groupsByCard[cardId];

    return {
      ...acc,
      [cardId]: sortGroup(group, events, compareFn, direction),
    };
  }, {});
