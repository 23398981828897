import PropTypes from 'prop-types';
import { Link, Reversible } from 'bv-components';
import { v as bvVar } from 'bv';
import ShowMore from '../common/show_more';
import Team from './team';

const EventDetailView = ({
  eventHref,
  opponentADescription,
  opponentBDescription,
  scoresA,
  scoresB,
  opponentAActive,
  opponentBActive,
  badges,
  eventId,
  showClock,
  defaultInPlay,
  showScoreboardHeaders,
  headers,
  sportId,
  americanFormat,
}) => (
  <Link to={eventHref} className={`inplay-coupon-competition-column inplay-sport-coupon-${sportId}`}>
    {showScoreboardHeaders && headers && (
    <span className="inplay-coupon-header-set-scores-headings">
      <span className="inplay-coupon-score-headers">
        {headers.map(
          (h) => <span key={h} className="inplay-coupon-score-label">{h}</span>,
        )}
      </span>
    </span>
    )}
    <Reversible reverse={americanFormat && bvVar('useAmericanFormat')}>
      <Team active={opponentAActive} description={opponentADescription} scores={scoresA} />
      <Team active={opponentBActive} description={opponentBDescription} scores={scoresB} />
    </Reversible>
    <ShowMore
      showClock={showClock}
      defaultInPlay={defaultInPlay}
      eventId={eventId}
      badges={badges}
    />
  </Link>
);

EventDetailView.propTypes = {
  americanFormat: PropTypes.bool.isRequired,
  eventHref: PropTypes.string.isRequired,
  opponentADescription: PropTypes.string.isRequired,
  opponentBDescription: PropTypes.string.isRequired,
  scoresA: PropTypes.instanceOf(Array).isRequired,
  scoresB: PropTypes.instanceOf(Array).isRequired,
  opponentAActive: PropTypes.bool.isRequired,
  opponentBActive: PropTypes.bool.isRequired,
  badges: PropTypes.instanceOf(Array).isRequired,
  eventId: PropTypes.number.isRequired,
  showClock: PropTypes.bool,
  defaultInPlay: PropTypes.bool,
  headers: PropTypes.isRequired,
  showScoreboardHeaders: PropTypes.bool,
  sportId: PropTypes.number.isRequired,
};

EventDetailView.defaultProps = {
  showClock: false,
  defaultInPlay: false,
  showScoreboardHeaders: false,
};

export default EventDetailView;
