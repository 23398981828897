import { t } from 'bv-i18n';
import { toCurrency } from 'bv-helpers/number';
import { BETSLIP_STATUS } from 'sportsbook-betslip-duck';
import { hasEnoughBalance } from '../helpers';

const SUBTITLEKEYS = {
  [BETSLIP_STATUS.SUBMIT]: 'submitting_subtitle',
  [BETSLIP_STATUS.DELAY]: 'delaying_subtitle',
};

const emptyFn = () => false;

export default ({
  balance,
  usePromocash,
  isAcceptPriceChangesLoading,
  onSubmit,
  onDeposit,
  onLogin,
  loggedIn,
  status,
  maxStake,
  submitText,
  getTotalStake,
  hasAnyLineChanges = emptyFn,
  hasAnyLineFollowed = emptyFn,
  hasAnyPriceChanges = emptyFn,
  hasAtLeastOneStake = emptyFn,
  isAllHidden = emptyFn,
  isAllHiddenOrSuspended = emptyFn,
  isAllSuspended = emptyFn,
}) => {
  const totalStake = getTotalStake();
  const buttonProps = { className: `btn btn-primary bvs-button is-primary multiline status-${status}`, onClick: onSubmit };

  let buttonText = submitText;
  let showSpinner = false;

  if (!loggedIn) {
    buttonText = t('betslip.button.login');
    buttonProps.onClick = onLogin;
    return { buttonProps, buttonText, showSpinner };
  }

  if (hasAtLeastOneStake()) {
    if (hasAnyLineChanges() || hasAnyLineFollowed()) {
      buttonProps.className += ' price-changed line-changed';
      buttonText = t('betslip.button.accept_changes_and_place_bets_with_lines');
    } else if (hasAnyPriceChanges()) {
      buttonProps.className += ' price-changed';
      buttonText = t('betslip.button.accept_changes_and_place_bets');
    }
  } else {
    buttonProps.disabled = true;
    buttonText = t('betslip.button.no_stake');
  }

  if (isAllHiddenOrSuspended() || isAllHidden()) {
    buttonProps.disabled = true;
    buttonProps.className = buttonProps.className.replace('btn-primary', 'btn-default');
    buttonText = t('betslip.button.not_available');
  }

  if (isAllSuspended() && !isAllHidden()) {
    buttonProps.disabled = true;
    buttonProps.className = buttonProps.className.replace('btn-primary', 'btn-default');
    buttonText = t('betslip.button.suspended');
  }

  if (
    totalStake > 0
    && !hasEnoughBalance(totalStake, balance, usePromocash)
    && !isAllSuspended()
  ) {
    if (usePromocash) {
      buttonProps.disabled = true;
      buttonText = t('betslip.button.not_enough_bonus_funds');
    } else {
      buttonText = t('betslip.button.deposit_to_bet');
      buttonProps.onClick = onDeposit;
    }
  }

  if (maxStake && totalStake > maxStake) {
    buttonProps.disabled = true;
    buttonText = t('betslip.button.max_stake_exceeded', { value: toCurrency(maxStake) });
  }

  if (isAcceptPriceChangesLoading) {
    buttonProps.disabled = true;
  }

  if (status === BETSLIP_STATUS.SUBMIT || status === BETSLIP_STATUS.DELAY) {
    buttonText = t('betslip.button.submitting');
    buttonProps.disabled = true;
    showSpinner = true;
  }

  return {
    buttonProps,
    buttonText,
    showSpinner,
    subtitleKey: SUBTITLEKEYS[status],
  };
};
